import React, { memo } from 'react';


const PricesPageComponent = () => {
  return (
    <div>
      PRICES
    </div>
  );
};

export const PricesPage = memo(PricesPageComponent);
