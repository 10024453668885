import React, { memo, useState } from 'react';

interface Props {
  placeholder: string;
  onChange: (v: string) => void;
}

function SearchInputComponent(props: Props) {
  const [value, setValue] = useState();

  const handleChange = ({ target }) => {
    setValue(target.value);
    props.onChange(target.value);
  };

  return (
    <div className="search-input">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path
          d="M11 19.5C15.4183 19.5 19 15.9183 19 11.5C19 7.08172 15.4183 3.5 11 3.5C6.58172 3.5 3 7.08172 3 11.5C3 15.9183 6.58172 19.5 11 19.5Z"
          stroke="#8C8C8C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.9984 21.4984L16.6484 17.1484"
          stroke="#8C8C8C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <input type="text" value={value} onChange={handleChange} placeholder={props.placeholder} />
    </div>
  );
}

export const SearchInput = memo(SearchInputComponent);
