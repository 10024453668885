import React, { memo, useMemo, useState } from 'react';
import {
  ContentBlock,
  ContentBlockType,
  DescriptionBlockParams,
  GalleryBlockParams,
  ImageBlockParams,
  SpecsBlockParams,
  TechnologyItem
} from '../../data';
import { AccordionContainer } from '../AccordeonContainer';
import { isMobile } from '../../constants';
import { BLine } from '../BLine';
import { AnimatedText } from '../AnimatedText';

interface Props {
  content: ContentBlock[];
}

function ImageBlock(params: ImageBlockParams) {
  return (
    <>
      <BLine />
      <div className="detail-content-block image">
        <div className="image-container">
          <img src={params.image} alt="image" />
        </div>
      </div>
    </>
  );
}

function DescriptionBlock(params: DescriptionBlockParams) {
  return (
    <>
      <BLine />
      <div className="detail-content-block description">
        <h2 className="title">
          <AnimatedText text={params.title} />
        </h2>
        <BLine />

        <div className="description-content">
          <div className="description-stack">
            {Boolean(params.technologies.length) && (
              <div className="description-technologies">
                {(() => {
                  const technologies: Array<Array<TechnologyItem>> = params.technologies.reduce((acc, curr, index) => {
                    if (index % 2 === 0) {
                      acc.push([curr]);
                    } else {
                      acc[acc.length - 1].push(curr);
                    }

                    return acc;
                  }, []);

                  if (params.technologies.length % 2 !== 0) {
                    technologies[technologies.length - 1].push(null);
                  }

                  return technologies.map((item) => (
                    <>
                      <div className="description-items">
                        {item.map(item => (
                          <div className="description-item">
                            <div className="description-item-icon">
                              <img src={`/assets/icons/technologies/${item.icon}`} alt='' />
                            </div>
                            <span>{item.label}</span>
                          </div>
                        ))}
                      </div>
                      <BLine />
                    </>
                  ))
                })()}
              </div>
            )}
            {Boolean(params.platform?.length) && (
              <div className="description-item inList">
                <span className="inList-title">Platform</span>
                <div className="tags">
                  {params.platform.map(v => (
                    <span key={v}>{v}</span>
                  ))}
                </div>
              </div>
            )}
            <BLine />
            {Boolean(params.genre?.length) && (
              <div className="description-item inList">
                <span className="inList-title">Genre</span>
                <div className="tags">
                  {params.genre.map(v => (
                    <span key={v}>{v}</span>
                  ))}
                </div>
              </div>
            )}
            <BLine />
            {Boolean(params.works?.length) && (
              <div className="description-item inList">
                <span className="inList-title">Works</span>
                <div className="tags">
                  {params.works.map(v => (
                    <span key={v}>{v}</span>
                  ))}
                </div>
              </div>
            )}
            <BLine />
            {Boolean(params.hours) && (
              <div className="description-item inList">
                <span className="inList-title">Total hours</span>
                <div className="tags">
                  <span>{params.hours}</span>
                </div>
              </div>
            )}
          </div>

          <div className="description-text">
            <p dangerouslySetInnerHTML={{ __html: params.text }} />
          </div>
        </div>
      </div>
    </>
  );
}

function SpecsBlock(params: SpecsBlockParams) {
  return (
    <>
      <BLine />
      <div className="detail-content-block specs">
        <AccordionContainer data={params.data} blockTitle="SPECIFICATION" />
      </div>
    </>
  );
}

function GalleryBlock(params: GalleryBlockParams) {
  const [index, setIndex] = useState(0);

  const handleSlideChange = (isNext = true) => {
    const delta = isNext ? 1 : -1;
    const nextIndex = index + delta;

    if (nextIndex < params.items.length && nextIndex >= 0) {
      setIndex(nextIndex);
    }
  };

  const translateX = useMemo(() => {
    if (!index) {
      return 0;
    }
    const gap = isMobile() ? 16 : 24;

    return `calc(${index * -100}% - ${index * gap}px)`;
  }, [index]);

  return (
    <div className="detail-content-block gallery">
      <h2 className="title">
        <AnimatedText text="IMAGES" />
      </h2>

      <BLine />

      <div className="gallery-content">
        <div className="gallery-list" style={{ '--translateX': translateX } as any}>
          {params.items.map(v => (
            <div className="gallery-list-item">
              <img src={v.image} alt={v.label} />
            </div>
          ))}
        </div>

        <div className="gallery-controls">
          <div className="gallery-controls-counter">
            <span>
              {index + 1}/{params.items.length}
            </span>
          </div>
          <BLine />
          <div className="gallery-controls-label">{params.items[index].label}</div>
          <BLine />
          <div className="gallery-controls-buttons">
            <div className="gallery-controls-buttons-item prev" onClick={() => handleSlideChange(false)}></div>
            <div className="gallery-controls-buttons-item next" onClick={() => handleSlideChange()}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

function DetailContentComponent(props: Props) {
  const renderContentBlock = (block: ContentBlock) => {
    const type = block.type;
    const params = { ...block.data } as any;

    switch (type) {
      case ContentBlockType.image: {
        return <ImageBlock {...params} />;
      }
      case ContentBlockType.description: {
        return <DescriptionBlock {...params} />;
      }
      case ContentBlockType.specs: {
        return <SpecsBlock {...params} />;
      }
      case ContentBlockType.gallery: {
        return <GalleryBlock {...params} />;
      }
    }
  };

  if (!props.content) {
    return;
  }

  return <div className="detail-content">{props.content.map(renderContentBlock)}</div>;
}

export const DetailContent = memo(DetailContentComponent);
