import React, { memo, useCallback, useState } from 'react';
import cx from 'classnames';
import { isMobile } from '../constants';
import { Title } from './Title';
import { BLine } from './BLine';

export interface AccordionContainerItem {
  icon?: string;
  title: string;
  content: any;
}

interface Props {
  data: AccordionContainerItem[];
  blockTitle: string;
  blockTitleLink?: string;
}

function AccordionComponent({ data, blockTitleLink, blockTitle }: Props) {
  const [activeContentId, setActiveContentId] = useState<number>(0);

  const onItemClick = useCallback(
    (id: number) => {
      if (isMobile()) {
        setActiveContentId(prevId => (prevId === id ? null : id));
      } else {
        setActiveContentId(id);
      }
    },
    [activeContentId]
  );

  return (
    <div>
      <Title label={blockTitle} link={blockTitleLink} />
      <BLine />
      <div className="accordionContainer">
        <div className="sections">
          {data.map((v, i) => (
            <div key={v.title}>
              {i !== 0 && <BLine />}

              <div
                key={v.title}
                className={cx('sections-item', { active: activeContentId === i })}
                onClick={() => onItemClick(i)}
              >
                {v.icon && <div className="sections-item-icon">{v.icon}</div>}
                <span>{v.title}</span>
                <svg
                  className="sections-item-arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M6 9L12 15L18 9"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="activeSection">{data[activeContentId]?.content}</div>
            </div>
          ))}
        </div>
        {data[activeContentId] && <div className="activeSection">{data[activeContentId].content}</div>}
      </div>
      <BLine />
    </div>
  );
}

export const AccordionContainer = memo(AccordionComponent);
