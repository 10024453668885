import React, { memo, useMemo } from 'react';
import cx from 'classnames';

interface Props {
  page: number;
  perPage: number;
  total: number;
  onPageChange: (page: number) => void;
}

function PaginationComponent({ page, perPage, total, onPageChange }: Props) {

  const totalPages = useMemo(() => Math.round(total / perPage), [total, perPage]);

  const pages = useMemo<any[]>(() => {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }, [page, totalPages]);

  const handlePage = (v: any) => {
    if (isNaN(v)) {
      return;
    }

    onPageChange(v);
  }

  return (
    <div className='pagination'>
      <div className='pagination-content'>
        <div className='pagination-button back'>
          <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
            <path
              d='M1.29289 12.7071C0.902369 12.3166 0.902369 11.6834 1.29289 11.2929L7.65685 4.92893C8.04738 4.53841 8.68054 4.53841 9.07107 4.92893C9.46159 5.31946 9.46159 5.95262 9.07107 6.34315L3.41421 12L9.07107 17.6569C9.46159 18.0474 9.46159 18.6805 9.07107 19.0711C8.68054 19.4616 8.04738 19.4616 7.65685 19.0711L1.29289 12.7071ZM22 13H2V11H22V13Z'
              fill='#141313' />
          </svg>
          <span>BACK</span>
        </div>

        <div className='pagination-main'>
          {pages.map(v => (
            <div
              key={v}
              className={cx('pagination-main-item', { active: page === v })}
              onClick={() => handlePage(v)}
            >{v}</div>
          ))}
        </div>

        <div className='pagination-button next'>
          <span>NEXT</span>
          <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
            <path
              d='M22.7071 12.7071C23.0976 12.3166 23.0976 11.6834 22.7071 11.2929L16.3431 4.92893C15.9526 4.53841 15.3195 4.53841 14.9289 4.92893C14.5384 5.31946 14.5384 5.95262 14.9289 6.34315L20.5858 12L14.9289 17.6569C14.5384 18.0474 14.5384 18.6805 14.9289 19.0711C15.3195 19.4616 15.9526 19.4616 16.3431 19.0711L22.7071 12.7071ZM2 13H22V11H2V13Z'
              fill='#141313' />
          </svg>
        </div>
      </div>
    </div>
  );
}

export const Pagination = memo(PaginationComponent);
