import React, { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { WORKS_DATA, WorksItem } from '../../data';
import { AnimatedText, BLine, DetailContent, Forms } from '../../components';

function DetailWorkComponent() {
  const params = useParams();
  const [item, setItem] = useState<WorksItem>();

  useEffect(() => {
    setItem(WORKS_DATA.find(v => v.id === params.workId));
  }, [params]);

  if (!item) {
    return;
  }

  return (
    <div className="detailWork-page">
      <div className="detail-title">
        <h2 className="title">
          <AnimatedText text={item.name} />
          <a href="/works" className="roundedButton transparent reverse"></a>
        </h2>

        <div className="tags">
          {item.genre.map(v => (
            <span key={v}>{v}</span>
          ))}
        </div>

        {Boolean(item.links?.length) && (
          <div className="links">
            {item.links.map(v => (
              <a href={v.link}>{v.icon}</a>
            ))}
          </div>
        )}
      </div>

      <DetailContent content={item.content} />

      <BLine />

      <Forms />
    </div>
  );
}

export const DetailWork = memo(DetailWorkComponent);
