import { ContentBlock, ContentBlockType } from './content';

interface WorkLinks {
  icon: string;
  link: string;
}

export interface WorksItem {
  id: string;
  name: string;
  description: string;
  genre: string[];
  where: string[];
  tech: string[];
  img?: string;
  links?: WorkLinks[];
  content?: ContentBlock[];
}

export const WORKS_DATA: WorksItem[] = [
  {
    id: 'RedwingsAirlines',
    name: 'Redwings Airlines',
    description: 'OTAs are platforms that allow travelers to book flights, accommodations, and other travel services online. They provide a convenient way to compare prices, read reviews, and make reservations. Examples include Expedia, Booking.com, and...',
    genre: ['Booking', 'TravelTech', 'b2b', 'Payment'],
    where: ['Mobile App', 'UI', 'Back-end', 'Front-end'],
    tech: ['Flutter', 'Golang', 'API Integrations'],
    links: [],
    img: 'https://www.viewhotels.jp/ryogoku/wp-content/uploads/sites/9/2020/03/test-img.jpg',
    content: [
      {
        type: ContentBlockType.image,
        data: { image: '' }
      },
      {
        type: ContentBlockType.description,
        data: {
          title: 'About project',
          text: 'Development of a cross-platform mobile app for an airline with a wide range of functionality-from searching and purchasing air tickets to ordering additional services for a flight and getting full information about your flight <br /><br /> Development of a cross-platform mobile app for an airline with a wide range of functionality-from searching and purchasing air tickets to ordering additional services for a flight and getting full information about your flight',
          hours: 1456,
          platform: ['iOS', 'Android', 'Web App'],
          genre: ['Booking', 'TravelTech', 'b2b', 'Payment'],
          works: ['Mobile', 'UI', 'Back-end', 'Front-end'],
          technologies: [
            { icon: 'react-native.svg', label: 'React Native' },
            { icon: 'java.svg', label: 'Java' },
            { icon: 'swift.svg', label: 'Swift' },
            { icon: 'mongoDB.svg', label: 'MongoDB' },
          ]
        }
      },
      {
        type: ContentBlockType.specs,
        data: {
          data: [
            { title: 'Personal account', content: 'Development' },
            { title: 'Payment methods', content: 'Development' },
            { title: 'Admin panel', content: 'Development' },
            { title: 'Search of tickets', content: 'Development' },
            { title: 'Booking', content: 'Development' }
          ]
        }
      },
      {
        type: ContentBlockType.gallery,
        data: {
          items: [
            { image: '', label: 'Подпись к картинке 1' },
            { image: '', label: 'Подпись 2' },
            { image: '', label: 'Подпись к картинке 3' },
            { image: '', label: 'Подпись 4' },
            { image: '', label: 'Подпись к картинке 5' }
          ]
        }
      },
    ]
  },
  {
    id: 'EveryLounge',
    name: 'EveryLounge',
    description: 'OTAs are platforms that allow travelers to book flights, accommodations, and other travel services online. They provide a convenient way to compare prices, read reviews, and make reservations. Examples include Expedia, Booking.com, and...',
    genre: ['TravelTech', 'b2b', 'Payment'],
    where: ['UI', 'Back-end'],
    tech: ['Golang'],
    img: 'https://www.viewhotels.jp/ryogoku/wp-content/uploads/sites/9/2020/03/test-img.jpg',
  },
  {
    id: 'Payment Systems',
    name: 'Payment Systems',
    description: 'OTAs are platforms that allow travelers to book flights, accommodations, and other travel services online. They provide a convenient way to compare prices, read reviews, and make reservations. Examples include Expedia, Booking.com, and...',
    genre: ['TravelTech', 'b2b', 'Peyment'],
    where: ['UI', 'Back-end'],
    tech: ['Golang'],
    img: 'https://www.viewhotels.jp/ryogoku/wp-content/uploads/sites/9/2020/03/test-img.jpg',
  },
  {
    id: 'Redwings Airlines 1',
    name: 'Redwings Airlines 1',
    description: 'OTAs are platforms that allow travelers to book flights, accommodations, and other travel services online. They provide a convenient way to compare prices, read reviews, and make reservations. Examples include Expedia, Booking.com, and...',
    genre: ['Booking', 'TravelTech', 'b2b', 'Payment'],
    where: ['Mobile App', 'UI', 'Back-end', 'Front-end'],
    tech: ['Flutter', 'Golang', 'API Integrations'],
    img: 'https://www.viewhotels.jp/ryogoku/wp-content/uploads/sites/9/2020/03/test-img.jpg',
  },
  {
    id: 'EveryLounge 1',
    name: 'EveryLounge 1',
    description: 'OTAs are platforms that allow travelers to book flights, accommodations, and other travel services online. They provide a convenient way to compare prices, read reviews, and make reservations. Examples include Expedia, Booking.com, and...',
    genre: ['TravelTech', 'b2b', 'Payment'],
    where: ['UI', 'Back-end'],
    tech: ['Golang'],
    img: 'https://www.viewhotels.jp/ryogoku/wp-content/uploads/sites/9/2020/03/test-img.jpg',
  },
  {
    id: 'Payment Systems 1',
    name: 'Payment Systems 1',
    description: 'OTAs are platforms that allow travelers to book flights, accommodations, and other travel services online. They provide a convenient way to compare prices, read reviews, and make reservations. Examples include Expedia, Booking.com, and...',
    genre: ['TravelTech', 'b2b', 'Peyment'],
    where: ['UI', 'Back-end'],
    tech: ['Golang'],
    img: 'https://www.viewhotels.jp/ryogoku/wp-content/uploads/sites/9/2020/03/test-img.jpg',
  },
  {
    id: 'Redwings Airlines 2',
    name: 'Redwings Airlines 2',
    description: 'OTAs are platforms that allow travelers to book flights, accommodations, and other travel services online. They provide a convenient way to compare prices, read reviews, and make reservations. Examples include Expedia, Booking.com, and...',
    genre: ['Booking', 'TravelTech', 'b2b', 'Payment'],
    where: ['Mobile App', 'UI', 'Back-end', 'Front-end'],
    tech: ['Flutter', 'Golang', 'API Integrations'],
    img: 'https://www.viewhotels.jp/ryogoku/wp-content/uploads/sites/9/2020/03/test-img.jpg',
  },
  {
    id: 'EveryLounge 2',
    name: 'EveryLounge 2',
    description: 'OTAs are platforms that allow travelers to book flights, accommodations, and other travel services online. They provide a convenient way to compare prices, read reviews, and make reservations. Examples include Expedia, Booking.com, and...',
    genre: ['TravelTech', 'b2b', 'Payment'],
    where: ['UI', 'Back-end'],
    tech: ['Golang'],
    img: 'https://www.viewhotels.jp/ryogoku/wp-content/uploads/sites/9/2020/03/test-img.jpg',
  },
  {
    id: 'Redwings Airlines 3',
    name: '9',
    description: 'OTAs are platforms that allow travelers to book flights, accommodations, and other travel services online. They provide a convenient way to compare prices, read reviews, and make reservations. Examples include Expedia, Booking.com, and...',
    genre: ['TravelTech', 'b2b', 'Peyment'],
    where: ['UI', 'Back-end'],
    tech: ['Golang'],
    img: 'https://www.viewhotels.jp/ryogoku/wp-content/uploads/sites/9/2020/03/test-img.jpg',
  },
  {
    id: '1',
    name: '10',
    description: 'OTAs are platforms that allow travelers to book flights, accommodations, and other travel services online. They provide a convenient way to compare prices, read reviews, and make reservations. Examples include Expedia, Booking.com, and...',
    genre: ['TravelTech', 'b2b', 'Payment'],
    where: ['UI', 'Back-end'],
    tech: ['Golang']
  },
  {
    id: '1',
    name: '11',
    description: 'OTAs are platforms that allow travelers to book flights, accommodations, and other travel services online. They provide a convenient way to compare prices, read reviews, and make reservations. Examples include Expedia, Booking.com, and...',
    genre: ['TravelTech', 'b2b', 'Peyment'],
    where: ['UI', 'Back-end'],
    tech: ['Golang']
  },
  {
    id: '1',
    name: '12',
    description: 'OTAs are platforms that allow travelers to book flights, accommodations, and other travel services online. They provide a convenient way to compare prices, read reviews, and make reservations. Examples include Expedia, Booking.com, and...',
    genre: ['TravelTech', 'b2b', 'Peyment'],
    where: ['UI', 'Back-end'],
    tech: ['Golang']
  }
];
