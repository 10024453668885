import React, { memo } from 'react';
import { AnimatedText } from './AnimatedText';

interface Props {
  label: string;
  link?: string;
}

function TitleContainer(props: Props) {
  return (
    <h2 className="title">
      <AnimatedText text={props.label} />
      {props.link && <a href={props.link} className="roundedButton"></a>}
    </h2>
  );
}

export const Title = memo(TitleContainer);
