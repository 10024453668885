import { WorksItem } from '../data';
import React, { memo, useMemo, useState } from 'react';
import { isMobile } from '../constants';
import cx from 'classnames';
import { BLine } from './BLine';

const TABLE_ITEMS_COUNT = 8;
const ITEMS_IN_ROW = 4;

interface Props {
  items: WorksItem[];
}

function ExpandableTableComponent(props: Props) {
  const [activeItem, setActiveItem] = useState(null);
  const [activeItemCoord, setActiveItemCoord] = useState<[number, number]>(null);

  const handleActiveItem = (e: any, index: number, coords: [number, number]) => {
    if (isMobile()) {
      const parent = e.target.closest('.expandable-table-item');
      if (parent) {
        parent.querySelector('.expandable-table-item-link')?.click();
      }
      return;
    }

    if (!e || !!(e as any).target.closest('.expandable-table-item-close')) {
      setActiveItem(null);
      return;
    }

    setActiveItem(index);
    setActiveItemCoord(coords);
  };

  const tables = useMemo(() => {
    return Array.from({ length: Math.ceil(props.items.length / TABLE_ITEMS_COUNT) }, (_, i) => {
      const items = props.items.slice(i * TABLE_ITEMS_COUNT, i * TABLE_ITEMS_COUNT + TABLE_ITEMS_COUNT);
      if (!isMobile()) {
        while (items.length < TABLE_ITEMS_COUNT) {
          items.push(null);
        }
      }
      return items;
    });
  }, [props.items]);

  return (
    <div className="expandable-table-container">
      <BLine />
      {tables.map((items, tableIndex) => (
        <div
          className={cx('expandable-table', {
            hasActive:
              activeItem !== null &&
              activeItem >= tableIndex * TABLE_ITEMS_COUNT &&
              activeItem < tableIndex * TABLE_ITEMS_COUNT + TABLE_ITEMS_COUNT
          })}
        >
          {items.map((v, i) => {
            const skip = tableIndex * TABLE_ITEMS_COUNT;
            const itemIndex = skip + i;
            const row = Math.floor(itemIndex / ITEMS_IN_ROW) + 1;
            const col = (itemIndex % ITEMS_IN_ROW) + 1;

            const firstItemInRow = itemIndex % ITEMS_IN_ROW === 0;
            const lastRowItem = itemIndex >= tables.length * TABLE_ITEMS_COUNT - ITEMS_IN_ROW;

            return (
              <div
                className={cx('expandable-table-item', {
                  active: activeItem === itemIndex,
                  activeRow: row === activeItemCoord?.[0],
                  activeCol: col === activeItemCoord?.[1],
                  firstItemInRow,
                  lastRowItem
                })}
                onClick={e => handleActiveItem(e, v ? itemIndex : null, [row, col])}
              >
                {v && (
                  <>
                    <div className="expandable-table-item-close" onClick={() => setActiveItem(null)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M18 6L6 18"
                          stroke="#DAA6FF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6 6L18 18"
                          stroke="#DAA6FF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>

                    <div className="tags">
                      {v.genre.map(item => (
                        <span>{item}</span>
                      ))}
                    </div>

                    <div className="expandable-table-item-content">
                      <div className="expandable-table-item-content-exp">
                        <div
                          className="expandable-table-item-img"
                          style={{ 'background-image': `url(${v.img})` } as any}
                        />

                        <h2 className="expandable-table-item-title">{v.name}</h2>

                        <p className="expandable-table-item-description">{v.description}</p>

                        <a href={`/works/${v.id}`} className="expandable-table-item-link">
                          READ MORE
                        </a>
                      </div>
                      <h2 className="expandable-table-item-title">{v.name}</h2>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      ))}
      <BLine />
    </div>
  );
}

export const ExpandableTable = memo(ExpandableTableComponent);
