import cx from 'classnames';
import { memo, useEffect, useRef, useState } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';

interface Props {
  vertical?: boolean;
}

function BLineContainer(props: Props) {
  const [inView, setInView] = useState(false);
  const line = useRef();
  const data = useIntersectionObserver(line, {
    threshold: 0,
    root: null
  });

  useEffect(() => {
    if (data?.isIntersecting) {
      setInView(true);
    }
  }, [data?.isIntersecting]);

  return <div ref={line} className={cx('bline', { vertical: props.vertical, inView })}></div>;
}


export const BLine = memo(BLineContainer);
