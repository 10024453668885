import { AccordionContainerItem } from '../components';

export enum ContentBlockType {
  image,
  description,
  specs,
  gallery
}

export interface ImageBlockParams {
  image: string;
}

export interface TechnologyItem {
  icon: string; label: string;
}

export interface DescriptionBlockParams {
  title: string;
  text: string;
  hours: number;
  platform: string[];
  genre: string[];
  works: string[];
  technologies: TechnologyItem[];
}

export interface SpecsBlockParams {
  data: AccordionContainerItem[];
}

export interface GalleryBlockParams {
  items: { image: string; label: string }[]
}

export interface ContentBlock {
  type: ContentBlockType;
  data: ImageBlockParams | DescriptionBlockParams | SpecsBlockParams | GalleryBlockParams;
}
