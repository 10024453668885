import { BufferAttribute, BufferGeometry, Points } from 'three'
import { R, areaParticlesCount } from './utils/const'
import { getRandomBallPoint } from './utils/getRandomBallPoint'
import { glRotate } from './utils/glUtils'
import { ParticlesMaterial } from './mainParticles'



const geometry = new BufferGeometry()
const positionArray = new Float32Array(areaParticlesCount * 3)
const sizeArray = new Float32Array(areaParticlesCount)

const Rmax = 40


for (let i = 0; i < areaParticlesCount; i++) {
  const r = getRandomBallPoint(6, Rmax)

  positionArray[i * 3 + 0] = r.x
  positionArray[i * 3 + 1] = r.y
  positionArray[i * 3 + 2] = Math.max(r.z * 0.25, 0)

  sizeArray[i] = Math.random() * 0.6 + 0.4
}


geometry.setAttribute('position', new BufferAttribute(positionArray, 3))
geometry.setAttribute('size', new BufferAttribute(sizeArray, 1))

const material = new ParticlesMaterial()

export const areaParticles = new Points(geometry, material)



//// SHADER

material.vertexShader = /*glsl*/`
uniform mat4 projectionMatrix;
uniform mat4 viewMatrix;
uniform mat4 modelMatrix;

attribute vec3 position;
attribute float size;

//
uniform float uTime;
uniform float uPosScale;

uniform float uInitRotationSpeed;

uniform float uPixelRatio;
uniform float uPointSizeScale;

${glRotate}


void main()
{
  vec3 p = position;
  float lp = length(p.xy);

  // rotate
  p.xy = rotate(p.xy, uTime * 0.1 - uInitRotationSpeed);

  // make a whole in the center
  float l = clamp(lp, ${R.min + 1}.0, ${Rmax}.0);
  p.xy = normalize(p.xy) * l;

  vec4 vp = viewMatrix * modelMatrix * vec4(uPosScale * p, 1.0);
  gl_Position = projectionMatrix * vp;
  gl_PointSize = uPointSizeScale * size * uPixelRatio * 10. / -vp.z;
}`
