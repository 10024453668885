import React, { memo } from 'react';
import { ReviewStars } from 'assets/icons';
import { BLine, Title } from '../../components';
import { isMobile } from '../../constants';

interface ReviewItem {
  comment: string;
  userName: string;
  position: string;
}

const DATA: ReviewItem[] = [
  {
    comment: '"They were skilled in design, and the speed of their delivery was incredible."',
    userName: 'Kristina Tsykvas',
    position: 'Co-Owner, Clazzy Swim'
  },
  {
    comment: '"They were skilled in design, and the speed of their delivery was incredible."',
    userName: 'Kristina Tsykvas',
    position: 'Co-Owner, Clazzy Swim'
  },
  {
    comment: '"They were skilled in design, and the speed of their delivery was incredible."',
    userName: 'Kristina Tsykvas',
    position: 'Co-Owner, Clazzy Swim'
  }
];

function ReviewsContainer() {
  return (
    <div>
      <Title label="CLUTCH REVIEWS" link="#" />
      <BLine />
      <div className="reviews">
        {DATA.map((review, i) => (
          <>
            {i !== 0 && <BLine vertical={!isMobile()} />}
            <div key={review.userName} className="reviews-item">
              <div>
                <div className="rate">
                  <span>5.0</span>
                  <ReviewStars />
                </div>
                <div className="comment">{review.comment}</div>
              </div>
              <div>
                <p>{review.userName}</p>
                <b>{review.position}</b>
              </div>
            </div>
          </>
        ))}
      </div>
      <BLine />
    </div>
  );
}

export const Reviews = memo(ReviewsContainer);
