import React, { memo, useState } from 'react';
import { Logo } from 'assets';
import { Link, NavLink } from 'react-router-dom';
import cx from 'classnames';
import { BLine } from '../BLine';

interface MenuItem {
  path: string;
  label: string;
}

const MENU: MenuItem[] = [
  { path: 'services', label: 'Services' },
  { path: 'prices', label: 'Prices' },
  { path: 'works', label: 'Works' },
  { path: 'about', label: 'About' },
  { path: 'contact', label: 'Contact' }
];

function HeaderComponent() {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const toggleMobileMenu = () => {
    setMenuIsOpen(value => !value);
    document.body.style.overflow = menuIsOpen ? '' : 'hidden';
  };

  const Navigation = () => {
    return (
      <nav className="navigation">
        <ul>
          {MENU.map(item => (
            <li key={item.path}>
              <NavLink
                to={item.path}
                onClick={() => setMenuIsOpen(false)}
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                {item.label}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    );
  };

  return (
    <>
      <header className={cx({ menuIsOpen })}>
        <BLine />

        <div className="header-main">
          <Link to="/" className="header-logo">
            <Logo />
          </Link>

          <Navigation />

          <div className="mobileMenu_btn">
            <button onClick={toggleMobileMenu} className={cx({ active: menuIsOpen })}></button>
          </div>

          <div className={cx('mobileMenu', { open: menuIsOpen })}>
            <Navigation />
          </div>
        </div>

        <BLine />
      </header>
    </>
  );
}

export const Header = memo(HeaderComponent);
