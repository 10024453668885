import React, { memo } from 'react';


const AboutPageComponent = () => {
  return (
    <div>
      ABOUT
    </div>
  );
};

export const AboutPage = memo(AboutPageComponent);
