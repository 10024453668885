import React, { memo } from 'react';
import SphereImg from '../../../assets/img/sphere.png';
import { BLine, Title } from '../../components';

function FindUsContainer() {
  return (
    <div>
      <Title label="FIND US" link="#" />
      <BLine />
      <div className="findUs">
        <div className="sphere">
          <img src={SphereImg} alt="Spere image" />
        </div>
        <BLine vertical />
        <div className="contacts">
          <div className="contacts-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
                fill="#DAA6FF"
              />
              <path
                d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
                fill="#1C1C1C"
              />
            </svg>
            <a
              href="https://www.google.com/maps/place/Puhangu+26,+10311+Tallinn,+%D0%AD%D1%81%D1%82%D0%BE%D0%BD%D0%B8%D1%8F/@59.4443471,24.6886854,17z/data=!3m1!4b1!4m6!3m5!1s0x4692947652205975:0x2e7e128d7d36cc5!8m2!3d59.4443444!4d24.6912603!16s%2Fg%2F11c4r7qt3w?entry=ttu"
              target="_blank"
            >
              Puhangu 26, Tallinn, 11412, Estonia
            </a>
          </div>

          <div className="contacts-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path
                d="M12.25 16.3789C14.4591 16.3789 16.25 14.588 16.25 12.3789C16.25 10.1698 14.4591 8.37891 12.25 8.37891C10.0409 8.37891 8.25 10.1698 8.25 12.3789C8.25 14.588 10.0409 16.3789 12.25 16.3789Z"
                stroke="#DAA6FF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.25 8.37829V13.3783C16.25 14.1739 16.5661 14.937 17.1287 15.4996C17.6913 16.0622 18.4544 16.3783 19.25 16.3783C20.0457 16.3783 20.8087 16.0622 21.3713 15.4996C21.9339 14.937 22.25 14.1739 22.25 13.3783V12.3783C22.2499 10.1213 21.4862 7.93076 20.0833 6.16282C18.6803 4.39487 16.7206 3.15351 14.5226 2.64058C12.3247 2.12766 10.0179 2.37333 7.97736 3.33765C5.93677 4.30198 4.28241 5.92824 3.28327 7.95201C2.28413 9.97578 1.99898 12.278 2.47418 14.4844C2.94938 16.6908 4.15699 18.6715 5.90064 20.1046C7.64429 21.5376 9.82144 22.3386 12.0781 22.3774C14.3347 22.4162 16.5381 21.6905 18.33 20.3183"
                stroke="#DAA6FF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <a href="mailto:welcome@factory.codes" target="_blank">
              welcome@factory.codes
            </a>
          </div>

          <div className="contacts-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M21.9975 16.9201V19.9201C21.9986 20.1986 21.9416 20.4743 21.83 20.7294C21.7184 20.9846 21.5548 21.2137 21.3496 21.402C21.1443 21.5902 20.9021 21.7336 20.6382 21.8228C20.3744 21.912 20.0949 21.9452 19.8175 21.9201C16.7403 21.5857 13.7845 20.5342 11.1875 18.8501C8.77132 17.3148 6.72283 15.2663 5.18749 12.8501C3.49747 10.2413 2.44573 7.27109 2.11749 4.1801C2.0925 3.90356 2.12537 3.62486 2.21399 3.36172C2.30262 3.09859 2.44506 2.85679 2.63226 2.65172C2.81945 2.44665 3.0473 2.28281 3.30128 2.17062C3.55527 2.05843 3.82983 2.00036 4.10749 2.0001H7.10749C7.5928 1.99532 8.06328 2.16718 8.43125 2.48363C8.79922 2.80008 9.03957 3.23954 9.10749 3.7201C9.23411 4.68016 9.46894 5.62282 9.80749 6.5301C9.94204 6.88802 9.97115 7.27701 9.8914 7.65098C9.81164 8.02494 9.62635 8.36821 9.35749 8.6401L8.08749 9.9101C9.51105 12.4136 11.5839 14.4865 14.0875 15.9101L15.3575 14.6401C15.6294 14.3712 15.9726 14.1859 16.3466 14.1062C16.7206 14.0264 17.1096 14.0556 17.4675 14.1901C18.3748 14.5286 19.3174 14.7635 20.2775 14.8901C20.7633 14.9586 21.2069 15.2033 21.524 15.5776C21.8412 15.9519 22.0097 16.4297 21.9975 16.9201Z"
                fill="#DAA6FF"
              />
            </svg>
            <a href="tel:+372 610 42 76" target="_blank">
              +372 610 42 76
            </a>
          </div>

          <div className="contacts-item socials">
            <a href="" className="socials-item">
              LINKEDIN
            </a>
            <a href="" className="socials-item">
              FACEBOOK
            </a>
            <a href="" className="socials-item">
              MEDIUM
            </a>
            <a href="" className="socials-item">
              GITHUB
            </a>
          </div>
        </div>
      </div>
      <BLine />
    </div>
  );
}

export const FindUs = memo(FindUsContainer);
