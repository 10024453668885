import { disposeParticles, initParticles } from 'app/components/particles'
import React, { memo, useEffect, useRef } from 'react';


function CanvasContainer() {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    initParticles(canvasRef.current)

    return disposeParticles;
  }, []);

  return <canvas ref={canvasRef} className="canvasWrapper"></canvas>;
}

export const ThreejsContainer = memo(CanvasContainer);
