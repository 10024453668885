import React, { memo } from 'react';
import { BLine, Title } from '../../components';

function AboutContainer() {
  return (
    <div>
      <Title label="ABOUT US" link="#" />
      <BLine />
      <div className="about">
        <div className="col">
          <div className="col-head">
            <p className="stroke">
              <svg xmlns="http://www.w3.org/2000/svg" width="700" height="72" viewBox="0 0 700 72">
                <text x="0" y="70">
                  +30
                </text>
              </svg>
            </p>
            <span>projects</span>
          </div>

          <div className="col-content">
            Each new project is an accumulation of our images, thoughts, and ideas, which together are transformed into
            the process of analyzing, studying and creating cool IT products and solutions for business
          </div>
        </div>

        <div className="col">
          <div className="col-head">
            <p className="stroke">
              <svg xmlns="http://www.w3.org/2000/svg" width="700" height="72" viewBox="0 0 700 72">
                <text x="0" y="70">
                  9
                </text>
              </svg>
            </p>
            <span>services</span>
          </div>

          <div className="col-content">
            <ul>
              <li>Full-Stack Development</li>
              <li>Research & Development</li>
              <li>Support & QA</li>
              <li>Analytics</li>
              <li>Design Systems</li>
              <li>Interactive Prototyping</li>
              <li>Management</li>
              <li>Education</li>
            </ul>
          </div>
        </div>

        <div className="col">
          <div className="col-head">
            <p className="stroke">
              <svg xmlns="http://www.w3.org/2000/svg" width="700" height="72" viewBox="0 0 700 72">
                <text x="0" y="70">
                  20+
                </text>
              </svg>
            </p>
            <span>people</span>
          </div>

          <div className="col-content">
            We make great efforts to find people who will be close to us in spirit, ideology, and approach to work. This
            is why we are able to make high-quality IT products and be effective partners of our clients
          </div>
        </div>

        <div className="col">
          <div className="col-head">
            <p className="stroke">
              <svg xmlns="http://www.w3.org/2000/svg" width="700" height="72" viewBox="0 0 700 72">
                <text x="0" y="70">
                  2017
                </text>
              </svg>
            </p>
            <span>since</span>
          </div>

          <div className="col-content">
            We have been learning and developing, making mistakes and stopping, concentrating, and moving on. We are
            proud of our history and current achievements, but we are even more loyal to our future
          </div>
        </div>
      </div>
      <BLine />
    </div>
  );
}

export const About = memo(AboutContainer);
