export const glRotate = /*glsl*/`
vec2 rotate(vec2 v, float a)
{
	float s = sin(a);
	float c = cos(a);
	mat2 m = mat2(c, s, -s, c);
	return m * v;
}`

export const glPow2 = /*glsl*/`float pow2(float v) { return v * v; }`
export const glPow3 = /*glsl*/`float pow3(float v) { return v * v * v; }`
export const glPow4 = /*glsl*/`float pow4(float v) { return v * v * v * v; }`
export const glPow5 = /*glsl*/`float pow5(float v) { return v * v * v * v * v; }`

export function float(n: number) {
  const v = n.toString()
  return v.includes('.') ? v : v + '.0'
}
