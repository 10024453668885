export const fragmentShader = /*glsl*/`
precision highp float;
uniform float uOpacity;
void main()
{
    float d = 0.5 - distance(gl_PointCoord, vec2(0.5));
    float strength = smoothstep(0.1, 0.4, d) * uOpacity;

    gl_FragColor = vec4(vec3(1.0), strength);
}`
