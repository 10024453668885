import React, { memo, useCallback, useState } from 'react';
import cx from 'classnames';
import { BLine } from './BLine';

const SuccessMessage = 'Great! We will answer you';
const EmailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

function FormsContainer() {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState('');

  const handleEmail = ({ target }) => {
    setEmail(target.value.replace(' ', '').trim().slice(0, 30));
  };

  const handleFocus = () => {
    if (success) {
      setEmail('');
    }
    setError(false);
    setSuccess(false);
  };

  const onSubmit = useCallback(() => {
    setError(false);

    if (!email || !EmailReg.test(email)) {
      setError(true);
      return;
    }

    const data = new FormData();
    data.append('email', email);

    fetch('/php/send.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(() => {
        setSuccess(true);
        setEmail(SuccessMessage);
      })
      .catch(() => alert('Error'));
  }, [email]);

  return (
    <div className="forms">
      <div className="forms-section call">
        <div className="forms-title">WANT TO CALL?</div>
        <div className="forms-section--content">
          <div className="forms-subTitle">catch us in callendly</div>
          <a href="#" className="roundedButton" />
        </div>
      </div>

      <BLine />

      <div className={cx('forms-section feedback', { error, success })}>
        <div className="forms-title">need FEEDBACK?</div>

        <div className="forms-section--content">
          <div className="input">
            <input
              type="text"
              placeholder="Your e-mail Address"
              value={email}
              onChange={handleEmail}
              onFocus={handleFocus}
            />
            {error && <span className="error-message">Unacceptable symbols</span>}
          </div>
        </div>

        <button className={cx('roundedButton', { close: error, check: success })} onClick={onSubmit}></button>
      </div>

      <BLine />
    </div>
  );
}

export const Forms = memo(FormsContainer);
