import React, { memo } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { SOCIALS } from '../../data';
import { BLine, DashedDivider } from '../../components';
import 'leaflet/dist/leaflet.css';

const defaultCenter: any = [59.4443444, 24.69126];

const ContactPageComponent = () => {
  return (
    <div className="contacts">
      <h2 className="title">CONTACTS</h2>
      <BLine />
      <div className="contacts-content">
        <div className="contacts-links">
          <a
            href="https://www.google.com/maps/place/Puhangu+26,+10311+Tallinn,+%D0%AD%D1%81%D1%82%D0%BE%D0%BD%D0%B8%D1%8F/@59.4443444,24.6912603,17z/data=!3m1!4b1!4m6!3m5!1s0x4692947652205975:0x2e7e128d7d36cc5!8m2!3d59.4443444!4d24.6912603!16s%2Fg%2F11c4r7qt3w?entry=ttu"
            className="contacts-links-item"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
                fill="#DAA6FF"
              />
              <path
                d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
                fill="#1C1C1C"
              />
            </svg>
            <span>Puhangu 26, Tallinn, 11412, Estonia</span>
          </a>
          <DashedDivider />
          <a href="mailto:welcome@factory.codes" className="contacts-links-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path
                d="M12.25 16.3789C14.4591 16.3789 16.25 14.588 16.25 12.3789C16.25 10.1698 14.4591 8.37891 12.25 8.37891C10.0409 8.37891 8.25 10.1698 8.25 12.3789C8.25 14.588 10.0409 16.3789 12.25 16.3789Z"
                stroke="#DAA6FF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.25 8.37829V13.3783C16.25 14.1739 16.5661 14.937 17.1287 15.4996C17.6913 16.0622 18.4544 16.3783 19.25 16.3783C20.0457 16.3783 20.8087 16.0622 21.3713 15.4996C21.9339 14.937 22.25 14.1739 22.25 13.3783V12.3783C22.2499 10.1213 21.4862 7.93076 20.0833 6.16282C18.6803 4.39487 16.7206 3.15351 14.5226 2.64058C12.3247 2.12766 10.0179 2.37333 7.97736 3.33765C5.93677 4.30198 4.28241 5.92824 3.28327 7.95201C2.28413 9.97578 1.99898 12.278 2.47418 14.4844C2.94938 16.6908 4.15699 18.6715 5.90064 20.1046C7.64429 21.5376 9.82144 22.3386 12.0781 22.3774C14.3347 22.4162 16.5381 21.6905 18.33 20.3183"
                stroke="#DAA6FF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>welcome@factory.codes</span>
          </a>
          <DashedDivider />
          <a href="tel:+372 610 42 76" className="contacts-links-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M21.9975 16.9201V19.9201C21.9986 20.1986 21.9416 20.4743 21.83 20.7294C21.7184 20.9846 21.5548 21.2137 21.3496 21.402C21.1443 21.5902 20.9021 21.7336 20.6382 21.8228C20.3744 21.912 20.0949 21.9452 19.8175 21.9201C16.7403 21.5857 13.7845 20.5342 11.1875 18.8501C8.77132 17.3148 6.72283 15.2663 5.18749 12.8501C3.49747 10.2413 2.44573 7.27109 2.11749 4.1801C2.0925 3.90356 2.12537 3.62486 2.21399 3.36172C2.30262 3.09859 2.44506 2.85679 2.63226 2.65172C2.81945 2.44665 3.0473 2.28281 3.30128 2.17062C3.55527 2.05843 3.82983 2.00036 4.10749 2.0001H7.10749C7.5928 1.99532 8.06328 2.16718 8.43125 2.48363C8.79922 2.80008 9.03957 3.23954 9.10749 3.7201C9.23411 4.68016 9.46894 5.62282 9.80749 6.5301C9.94204 6.88802 9.97115 7.27701 9.8914 7.65098C9.81164 8.02494 9.62635 8.36821 9.35749 8.6401L8.08749 9.9101C9.51105 12.4136 11.5839 14.4865 14.0875 15.9101L15.3575 14.6401C15.6294 14.3712 15.9726 14.1859 16.3466 14.1062C16.7206 14.0264 17.1096 14.0556 17.4675 14.1901C18.3748 14.5286 19.3174 14.7635 20.2775 14.8901C20.7633 14.9586 21.2069 15.2033 21.524 15.5776C21.8412 15.9519 22.0097 16.4297 21.9975 16.9201Z"
                fill="#DAA6FF"
              />
            </svg>
            <span>+372 610 42 76</span>
          </a>
          <DashedDivider />
          <a href="#" className="contacts-links-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clipPath="url(#clip0_1853_790)">
                <path
                  d="M16.4515 15.559C15.6745 16.2344 14.7079 17.0751 12.9474 17.0751H11.8964C10.6241 17.0751 9.4671 16.6224 8.63886 15.7996C7.83024 14.996 7.38414 13.8969 7.38414 12.7033V11.2919C7.38414 10.0983 7.82952 8.99847 8.63886 8.19561C9.4671 7.37277 10.6241 6.92001 11.8964 6.92001H12.9474C14.7079 6.92001 15.6745 7.76073 16.4515 8.43609C17.2573 9.13695 17.9535 9.74205 19.8074 9.74205C20.0954 9.74205 20.3779 9.71931 20.6525 9.67587C20.6504 9.67035 20.6483 9.66555 20.6462 9.66003C20.536 9.39195 20.4059 9.12867 20.2564 8.87439L19.0164 6.76773C17.879 4.83468 15.7764 3.64453 13.5017 3.64453H11.0218C8.74705 3.64453 6.6444 4.83536 5.50703 6.76773L4.2671 8.87439C3.12972 10.8075 3.12972 13.1884 4.2671 15.1208L5.50703 17.2275C6.6444 19.1605 8.74705 20.3506 11.0218 20.3506H13.5017C15.7764 20.3506 17.879 19.1598 19.0164 17.2275L20.2564 15.1208C20.4059 14.8658 20.536 14.6032 20.6462 14.3352C20.6483 14.3296 20.6504 14.3248 20.6525 14.3193C20.3779 14.2759 20.0962 14.2531 19.8074 14.2531C17.9535 14.2531 17.2573 14.8582 16.4515 15.559Z"
                  fill="#DAA6FF"
                />
                <path
                  d="M12.9476 8.19531H11.8965C9.96046 8.19531 8.6875 9.55221 8.6875 11.2889V12.7002C8.6875 14.4369 9.95974 15.7938 11.8965 15.7938H12.9476C15.7688 15.7938 15.5475 12.9717 19.8076 12.9717C20.2158 12.9717 20.619 13.0083 21.0117 13.0799C21.141 12.3626 21.141 11.6279 21.0117 10.9098C20.619 10.9815 20.2158 11.018 19.8076 11.018C15.5475 11.0174 15.7688 8.19531 12.9476 8.19531Z"
                  fill="#DAA6FF"
                />
                <path
                  d="M23.457 14.1122C22.7292 13.5857 21.8946 13.2425 21.0094 13.0805C21.008 13.0874 21.0073 13.0943 21.0059 13.1012C20.93 13.5147 20.812 13.922 20.6504 14.3168C21.3972 14.4354 22.0857 14.711 22.6737 15.1335C22.6716 15.1397 22.6702 15.1459 22.6681 15.1528C22.3288 16.2334 21.8173 17.2519 21.1486 18.1788C20.4875 19.094 19.6887 19.8996 18.7733 20.5729C16.873 21.9712 14.6095 22.7099 12.2266 22.7099C10.7513 22.7099 9.321 22.4267 7.97496 21.8684C6.67458 21.3289 5.50631 20.5563 4.50241 19.5715C3.49852 18.5867 2.71101 17.4407 2.16094 16.1651C1.5919 14.8447 1.30316 13.4417 1.30316 11.9945C1.30316 10.5473 1.5919 9.14418 2.16094 7.82382C2.71101 6.54822 3.49852 5.40216 4.50241 4.41738C5.50631 3.4326 6.67458 2.66008 7.97496 2.12048C9.321 1.56228 10.7513 1.27904 12.2266 1.27904C14.6095 1.27904 16.873 2.01781 18.7733 3.41607C19.6887 4.08935 20.4875 4.89496 21.1486 5.81014C21.8173 6.73704 22.3288 7.7556 22.6681 8.83614C22.6702 8.84304 22.6723 8.84922 22.6737 8.85546C22.0857 9.27786 21.3972 9.55422 20.6504 9.67206C20.812 10.0676 20.9308 10.4756 21.0059 10.8891C21.0073 10.896 21.008 10.9022 21.0094 10.9091C21.8946 10.7471 22.7285 10.4039 23.457 9.87744C24.1546 9.37092 24.0197 8.79894 23.9136 8.45988C22.3758 3.56078 17.7252 0 12.2266 0C5.474 0 0 5.36977 0 11.9938C0 18.6178 5.474 23.9876 12.2266 23.9876C17.7252 23.9876 22.3758 20.4268 23.9136 15.5277C24.0197 15.1907 24.1553 14.6187 23.457 14.1122Z"
                  fill="#DAA6FF"
                />
                <path
                  d="M20.6512 9.67383C20.3765 9.71727 20.0948 9.74001 19.8061 9.74001C17.9522 9.74001 17.256 9.13491 16.4502 8.43405C15.6732 7.75869 14.7065 6.91797 12.946 6.91797H11.8951C10.6228 6.91797 9.46577 7.37073 8.63753 8.19357C7.82891 8.99709 7.38281 10.0963 7.38281 11.2899V12.7012C7.38281 13.8948 7.82819 14.9947 8.63753 15.7975C9.46577 16.6203 10.6228 17.0731 11.8951 17.0731H12.946C14.7065 17.0731 15.6732 16.2324 16.4502 15.557C17.256 14.8561 17.9522 14.2511 19.8061 14.2511C20.0941 14.2511 20.3765 14.2738 20.6512 14.3172C20.8128 13.9224 20.9308 13.5144 21.0067 13.1016C21.0081 13.0947 21.0088 13.0878 21.0102 13.0809C20.6175 13.0093 20.2142 12.9727 19.8061 12.9727C15.5461 12.9727 15.7673 15.7948 12.946 15.7948H11.8951C9.95897 15.7948 8.68595 14.4378 8.68595 12.7012V11.2899C8.68595 9.55323 9.95825 8.19633 11.8951 8.19633H12.946C15.7673 8.19633 15.5461 11.0184 19.8061 11.0184C20.2142 11.0184 20.6175 10.9818 21.0102 10.9101C21.0088 10.9033 21.0081 10.897 21.0067 10.8901C20.9315 10.4773 20.8128 10.0694 20.6512 9.67383Z"
                  fill="white"
                />
                <path
                  d="M20.6512 9.67383C20.3765 9.71727 20.0948 9.74001 19.8061 9.74001C17.9522 9.74001 17.256 9.13491 16.4502 8.43405C15.6732 7.75869 14.7065 6.91797 12.946 6.91797H11.8951C10.6228 6.91797 9.46577 7.37073 8.63753 8.19357C7.82891 8.99709 7.38281 10.0963 7.38281 11.2899V12.7012C7.38281 13.8948 7.82819 14.9947 8.63753 15.7975C9.46577 16.6203 10.6228 17.0731 11.8951 17.0731H12.946C14.7065 17.0731 15.6732 16.2324 16.4502 15.557C17.256 14.8561 17.9522 14.2511 19.8061 14.2511C20.0941 14.2511 20.3765 14.2738 20.6512 14.3172C20.8128 13.9224 20.9308 13.5144 21.0067 13.1016C21.0081 13.0947 21.0088 13.0878 21.0102 13.0809C20.6175 13.0093 20.2142 12.9727 19.8061 12.9727C15.5461 12.9727 15.7673 15.7948 12.946 15.7948H11.8951C9.95897 15.7948 8.68595 14.4378 8.68595 12.7012V11.2899C8.68595 9.55323 9.95825 8.19633 11.8951 8.19633H12.946C15.7673 8.19633 15.5461 11.0184 19.8061 11.0184C20.2142 11.0184 20.6175 10.9818 21.0102 10.9101C21.0088 10.9033 21.0081 10.897 21.0067 10.8901C20.9315 10.4773 20.8128 10.0694 20.6512 9.67383Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1853_790">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span className="underline">Calendly</span>
          </a>
        </div>

        <div className="contacts-map">
          <MapContainer center={defaultCenter} zoom={12}>
            <TileLayer
              attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
              url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
            />
            {/*<Marker position={defaultCenter} />*/}
          </MapContainer>
        </div>
      </div>
      <BLine />
      <h2 className="title">We’re SOCIAL</h2>
      <BLine />
      <div className="contacts-socials">
        {SOCIALS.map(v => (
          <a href={v.link} style={{ '--bg': v.bg } as any}>
            {v.label}
          </a>
        ))}
      </div>
      <BLine />
    </div>
  );
};

export const ContactPage = memo(ContactPageComponent);
