
export const ReviewStars = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="113" height="18" viewBox="0 0 113 18" fill="none">
    <path d="M8.00787 9.25908e-05V0.889764L8.89764 0.889669V0L8.00787 9.25908e-05Z" fill="white"/>
    <path d="M8.00787 0.889764V1.77953L8.89764 1.77943V0.889669L8.00787 0.889764Z" fill="white"/>
    <path d="M8.00787 1.77953V2.66929L8.89764 2.6692V1.77943L8.00787 1.77953Z" fill="white"/>
    <path d="M7.11811 1.77962V2.66929H8.00787V1.77953L7.11811 1.77962Z" fill="white"/>
    <path d="M8.89764 1.77943V2.6692H9.7874V1.77953L8.89764 1.77943Z" fill="white"/>
    <path d="M8.89764 2.6692V3.55905L9.7874 3.55896V2.6692H8.89764Z" fill="white"/>
    <path d="M7.11811 2.66929V3.55905L8.00787 3.55896V2.66929H7.11811Z" fill="white"/>
    <path d="M8.00787 2.66929V3.55896L8.89764 3.55905V2.6692L8.00787 2.66929Z" fill="white"/>
    <path d="M7.11811 3.55905V4.44882L8.00787 4.44872V3.55896L7.11811 3.55905Z" fill="white"/>
    <path d="M8.00787 3.55896V4.44872H8.89764V3.55905L8.00787 3.55896Z" fill="white"/>
    <path d="M9.7874 3.55896V4.44882L8.89764 4.44872V3.55905L9.7874 3.55896Z" fill="white"/>
    <path d="M6.22835 4.44891V5.33858L7.11811 5.33849V4.44882L6.22835 4.44891Z" fill="white"/>
    <path d="M6.22835 5.33858V6.22835L7.11811 6.22825V5.33849L6.22835 5.33858Z" fill="white"/>
    <path d="M2.66929 6.22844V7.11811L3.55905 7.11801V6.22835L2.66929 6.22844Z" fill="white"/>
    <path d="M1.77953 6.22844V7.11811H2.66929V6.22844H1.77953Z" fill="white"/>
    <path d="M4.44882 8.00797V8.89764L5.33858 8.89754V8.00787L4.44882 8.00797Z" fill="white"/>
    <path d="M7.11811 9.78749V10.6772L8.00787 10.6771V9.7874L7.11811 9.78749Z" fill="white"/>
    <path d="M7.11811 4.44882V5.33849H8.00787V4.44872L7.11811 4.44882Z" fill="white"/>
    <path d="M7.11811 5.33849V6.22825H8.00787V5.33849H7.11811Z" fill="white"/>
    <path d="M7.11811 6.22825V7.11811L8.00787 7.11801V6.22825H7.11811Z" fill="white"/>
    <path d="M9.7874 8.00797V8.89764L10.6772 8.89754V8.00787L9.7874 8.00797Z" fill="white"/>
    <path d="M3.55905 6.22835V7.11801H4.44882V6.22835H3.55905Z" fill="white"/>
    <path d="M2.66929 6.22844V7.11811L3.55905 7.11801V6.22835L2.66929 6.22844Z" fill="white"/>
    <path d="M5.33858 8.00787V8.89754H6.22835V8.00787H5.33858Z" fill="white"/>
    <path d="M8.00787 9.7874V10.6771H8.89764V9.7874H8.00787Z" fill="white"/>
    <path d="M8.00787 4.44872V5.33849H8.89764V4.44872H8.00787Z" fill="white"/>
    <path d="M8.00787 5.33849V6.22825H8.89764V5.33849H8.00787Z" fill="white"/>
    <path d="M8.00787 6.22825V7.11801H8.89764V6.22825H8.00787Z" fill="white"/>
    <path d="M10.6772 8.00787V8.89754H11.5669V8.00787H10.6772Z" fill="white"/>
    <path d="M10.6772 6.22844V7.11811L11.5669 7.11801V6.22835L10.6772 6.22844Z" fill="white"/>
    <path d="M13.3465 6.22844V7.11811L14.2362 7.11801V6.22835L13.3465 6.22844Z" fill="white"/>
    <path d="M4.44882 11.567V12.4567L5.33858 12.4566V11.5669L4.44882 11.567Z" fill="white"/>
    <path d="M7.11811 11.567V12.4567L8.00787 12.4566V11.5669L7.11811 11.567Z" fill="white"/>
    <path d="M9.7874 11.567V12.4567L10.6772 12.4566V11.5669L9.7874 11.567Z" fill="white"/>
    <path d="M10.6772 13.3465V14.2362L11.5669 14.2361V13.3465L10.6772 13.3465Z" fill="white"/>
    <path d="M3.55905 13.3465V14.2362L4.44882 14.2361V13.3465L3.55905 13.3465Z" fill="white"/>
    <path d="M4.44882 6.22835V7.11801H5.33858V6.22835H4.44882Z" fill="white"/>
    <path d="M3.55905 6.22835V7.11801H4.44882V6.22835H3.55905Z" fill="white"/>
    <path d="M6.22835 8.00787V8.89754H7.11811V8.00787H6.22835Z" fill="white"/>
    <path d="M8.89764 9.7874V10.6771H9.7874V9.7874H8.89764Z" fill="white"/>
    <path d="M9.7874 4.44882V5.33858L8.89764 5.33849V4.44872L9.7874 4.44882Z" fill="white"/>
    <path d="M9.7874 5.33858V6.22835L8.89764 6.22825V5.33849L9.7874 5.33858Z" fill="white"/>
    <path d="M9.7874 6.22835V7.11811L8.89764 7.11801V6.22825L9.7874 6.22835Z" fill="white"/>
    <path d="M12.4567 8.00797V8.89764L11.5669 8.89754V8.00787L12.4567 8.00797Z" fill="white"/>
    <path d="M12.4567 6.22844V7.11811L11.5669 7.11801V6.22835L12.4567 6.22844Z" fill="white"/>
    <path d="M15.126 6.22844V7.11811L14.2362 7.11801V6.22835L15.126 6.22844Z" fill="white"/>
    <path d="M6.22835 11.567V12.4567L5.33858 12.4566V11.5669L6.22835 11.567Z" fill="white"/>
    <path d="M8.89764 11.567V12.4567L8.00787 12.4566V11.5669L8.89764 11.567Z" fill="white"/>
    <path d="M11.5669 11.567V12.4567L10.6772 12.4566V11.5669L11.5669 11.567Z" fill="white"/>
    <path d="M12.4567 13.3465V14.2362L11.5669 14.2361V13.3465L12.4567 13.3465Z" fill="white"/>
    <path d="M5.33858 13.3465V14.2362L4.44882 14.2361V13.3465L5.33858 13.3465Z" fill="white"/>
    <path d="M6.22835 6.22835V7.11811L5.33858 7.11801V6.22835H6.22835Z" fill="white"/>
    <path d="M7.11811 6.22825V7.11811H6.22835V6.22835L7.11811 6.22825Z" fill="white"/>
    <path d="M10.6772 6.22844V7.11811H9.7874V6.22835L10.6772 6.22844Z" fill="white"/>
    <path d="M5.33858 6.22835V7.11801H4.44882V6.22835H5.33858Z" fill="white"/>
    <path d="M8.00787 8.00797V8.89764L7.11811 8.89754V8.00787L8.00787 8.00797Z" fill="white"/>
    <path d="M10.6772 9.78749V10.6772L9.7874 10.6771V9.7874L10.6772 9.78749Z" fill="white"/>
    <path d="M0.889764 6.22844L0.889764 7.11811L3.88886e-08 7.11801L0 6.22835L0.889764 6.22844Z" fill="white"/>
    <path d="M3.55905 8.00797V8.89764L2.66929 8.89754V8.00787L3.55905 8.00797Z" fill="white"/>
    <path d="M6.22835 9.78749V10.6772L5.33858 10.6771V9.7874L6.22835 9.78749Z" fill="white"/>
    <path d="M2.66929 8.00787V8.89754H1.77953V8.00787H2.66929Z" fill="white"/>
    <path d="M5.33858 9.7874V10.6771H4.44882V9.7874H5.33858Z" fill="white"/>
    <path d="M1.77953 7.11811V8.00787L0.889764 8.00778V7.11811H1.77953Z" fill="white"/>
    <path d="M4.44882 8.89764V9.7874L3.55905 9.78731V8.89764H4.44882Z" fill="white"/>
    <path d="M7.11811 10.6772V11.567H6.22835V10.6772H7.11811Z" fill="white"/>
    <path d="M6.22835 10.6772V11.567L5.33858 11.5669V10.6771L6.22835 10.6772Z" fill="white"/>
    <path d="M2.66929 7.11811V8.00787H1.77953V7.11811H2.66929Z" fill="white"/>
    <path d="M5.33858 8.89754V9.7874H4.44882V8.89764L5.33858 8.89754Z" fill="white"/>
    <path d="M8.00787 10.6771V11.5669L7.11811 11.567V10.6772L8.00787 10.6771Z" fill="white"/>
    <path d="M4.44882 7.11801V8.00797H3.55905V7.11801H4.44882Z" fill="white"/>
    <path d="M7.11811 8.89754V9.78749H6.22835V8.89754H7.11811Z" fill="white"/>
    <path d="M9.7874 10.6771V11.567H8.89764V10.6771H9.7874Z" fill="white"/>
    <path d="M3.55905 7.11801V8.00797L2.66929 8.00787V7.11811L3.55905 7.11801Z" fill="white"/>
    <path d="M6.22835 8.89754V9.78749L5.33858 9.7874V8.89754H6.22835Z" fill="white"/>
    <path d="M8.89764 10.6771V11.567L8.00787 11.5669V10.6771H8.89764Z" fill="white"/>
    <path d="M5.33858 7.11801V8.00787L4.44882 8.00797V7.11801H5.33858Z" fill="white"/>
    <path d="M8.00787 8.89764V9.7874L7.11811 9.78749V8.89754L8.00787 8.89764Z" fill="white"/>
    <path d="M10.6772 10.6772V11.5669L9.7874 11.567V10.6771L10.6772 10.6772Z" fill="white"/>
    <path d="M6.22835 7.11811V8.00787H5.33858V7.11801L6.22835 7.11811Z" fill="white"/>
    <path d="M8.89764 8.89773V9.7874H8.00787V8.89764L8.89764 8.89773Z" fill="white"/>
    <path d="M11.5669 10.6773V11.567L10.6772 11.5669V10.6772L11.5669 10.6773Z" fill="white"/>
    <path d="M7.11811 7.11811V8.00787H6.22835V7.11811H7.11811Z" fill="white"/>
    <path d="M9.7874 8.89764V9.7874H8.89764V8.89773L9.7874 8.89764Z" fill="white"/>
    <path d="M12.4567 9.78749V10.6772L11.5669 10.6773V9.7874L12.4567 9.78749Z" fill="white"/>
    <path d="M8.00787 7.11801V8.00797L7.11811 8.00787V7.11811L8.00787 7.11801Z" fill="white"/>
    <path d="M10.6772 8.89754V9.78749L9.7874 9.7874V8.89764L10.6772 8.89754Z" fill="white"/>
    <path d="M8.89764 7.11801V8.00787L8.00787 8.00797V7.11801H8.89764Z" fill="white"/>
    <path d="M11.5669 8.89754V9.7874L10.6772 9.78749V8.89754H11.5669Z" fill="white"/>
    <path d="M11.5669 7.11801V8.00787H10.6772V7.11811L11.5669 7.11801Z" fill="white"/>
    <path d="M14.2362 7.11801V8.00787L13.3465 8.00778V7.11811L14.2362 7.11801Z" fill="white"/>
    <path d="M5.33858 12.4566V13.3465L4.44882 13.3465V12.4567L5.33858 12.4566Z" fill="white"/>
    <path d="M8.00787 12.4566V13.3465L7.11811 13.3464V12.4567L8.00787 12.4566Z" fill="white"/>
    <path d="M10.6772 12.4566V13.3465L9.7874 13.3464V12.4567L10.6772 12.4566Z" fill="white"/>
    <path d="M11.5669 14.2361V15.126L10.6772 15.1259V14.2362L11.5669 14.2361Z" fill="white"/>
    <path d="M10.6772 13.3465V14.2362L9.7874 14.2361V13.3464L10.6772 13.3465Z" fill="white"/>
    <path d="M4.44882 14.2361V15.126L3.55905 15.1259V14.2362L4.44882 14.2361Z" fill="white"/>
    <path d="M4.44882 15.126V16.0157L3.55905 16.0157V15.1259L4.44882 15.126Z" fill="white"/>
    <path d="M13.3465 15.1261V16.0157L12.4567 16.0157V15.126L13.3465 15.1261Z" fill="white"/>
    <path d="M13.3465 16.0157V16.9055L12.4567 16.9054V16.0157L13.3465 16.0157Z" fill="white"/>
    <path d="M3.55905 15.1259V16.0157H2.66929V15.126L3.55905 15.1259Z" fill="white"/>
    <path d="M12.4567 15.126V16.0157H11.5669V15.126H12.4567Z" fill="white"/>
    <path d="M3.55905 16.0157V16.9055L2.66929 16.9054V16.0157H3.55905Z" fill="white"/>
    <path d="M2.66929 16.9054V17.7953L1.77953 17.7952V16.9055L2.66929 16.9054Z" fill="white"/>
    <path d="M4.44882 16.0157V16.9055H3.55905V16.0157L4.44882 16.0157Z" fill="white"/>
    <path d="M5.33858 15.1261V16.0157H4.44882V15.126L5.33858 15.1261Z" fill="white"/>
    <path d="M14.2362 15.1261V16.0157H13.3465V15.1261H14.2362Z" fill="white"/>
    <path d="M14.2362 16.0157V16.9055H13.3465V16.0157H14.2362Z" fill="white"/>
    <path d="M15.126 16.9056V17.7953L14.2362 17.7952V16.9055L15.126 16.9056Z" fill="white"/>
    <path d="M9.7874 7.11811V8.00797L8.89764 8.00787V7.11801L9.7874 7.11811Z" fill="white"/>
    <path d="M12.4567 8.89764V9.78749L11.5669 9.7874V8.89754L12.4567 8.89764Z" fill="white"/>
    <path d="M12.4567 7.11811V8.00797L11.5669 8.00787V7.11801L12.4567 7.11811Z" fill="white"/>
    <path d="M15.126 7.11811V8.00787H14.2362V7.11801L15.126 7.11811Z" fill="white"/>
    <path d="M15.126 8.00787V8.89764L14.2362 8.89754V8.00787H15.126Z" fill="white"/>
    <path d="M14.2362 8.00787V8.89754H13.3465V8.00778L14.2362 8.00787Z" fill="white"/>
    <path d="M16.0157 7.1182V8.00787H15.126V7.11811L16.0157 7.1182Z" fill="white"/>
    <path d="M6.22835 12.4567V13.3465L5.33858 13.3465V12.4566L6.22835 12.4567Z" fill="white"/>
    <path d="M8.89764 12.4567V13.3465H8.00787V12.4566L8.89764 12.4567Z" fill="white"/>
    <path d="M11.5669 12.4567V13.3465L10.6772 13.3465V12.4566L11.5669 12.4567Z" fill="white"/>
    <path d="M12.4567 14.2362V15.126H11.5669V14.2361L12.4567 14.2362Z" fill="white"/>
    <path d="M5.33858 14.2362V15.1261L4.44882 15.126V14.2361L5.33858 14.2362Z" fill="white"/>
    <path d="M6.22835 14.2363V15.126L5.33858 15.1261V14.2362L6.22835 14.2363Z" fill="white"/>
    <path d="M10.6772 7.11811V8.00787L9.7874 8.00797V7.11811H10.6772Z" fill="white"/>
    <path d="M13.3465 8.89754V9.7874L12.4567 9.78749V8.89764L13.3465 8.89754Z" fill="white"/>
    <path d="M13.3465 7.11811V8.00778L12.4567 8.00797V7.11811H13.3465Z" fill="white"/>
    <path d="M16.9055 6.22844V7.11811L16.0157 7.1182V6.22835L16.9055 6.22844Z" fill="white"/>
    <path d="M7.11811 12.4567V13.3464L6.22835 13.3465V12.4567H7.11811Z" fill="white"/>
    <path d="M9.7874 12.4567V13.3464L8.89764 13.3465V12.4567H9.7874Z" fill="white"/>
    <path d="M12.4567 12.4568V13.3465L11.5669 13.3465V12.4567L12.4567 12.4568Z" fill="white"/>
    <path d="M13.3465 14.2363V15.1261L12.4567 15.126V14.2362L13.3465 14.2363Z" fill="white"/>
    <path d="M7.11811 13.3464V14.2362L6.22835 14.2363V13.3465L7.11811 13.3464Z" fill="white"/>
    <path d="M10.6772 4.44891V5.33858H9.7874V4.44882L10.6772 4.44891Z" fill="white"/>
    <path d="M10.6772 5.33858V6.22844L9.7874 6.22835V5.33858H10.6772Z" fill="white"/>
    <path d="M13.3465 8.00778V8.89754L12.4567 8.89764V8.00797L13.3465 8.00778Z" fill="white"/>
    <path d="M13.3465 6.22844V7.11811H12.4567V6.22844H13.3465Z" fill="white"/>
    <path d="M16.0157 6.22835V7.1182L15.126 7.11811V6.22844L16.0157 6.22835Z" fill="white"/>
    <path d="M7.11811 11.567V12.4567H6.22835V11.567H7.11811Z" fill="white"/>
    <path d="M9.7874 11.567V12.4567H8.89764V11.567H9.7874Z" fill="white"/>
    <path d="M12.4567 11.567V12.4568L11.5669 12.4567V11.567H12.4567Z" fill="white"/>
    <path d="M13.3465 13.3465V14.2363L12.4567 14.2362V13.3465H13.3465Z" fill="white"/>
    <path d="M6.22835 13.3465V14.2363L5.33858 14.2362V13.3465L6.22835 13.3465Z" fill="white"/>
    <path d="M9.7874 8.00797V8.89764L8.89764 8.89773V8.00787L9.7874 8.00797Z" fill="white"/>
    <path d="M8.89764 8.00787V8.89773L8.00787 8.89764V8.00797L8.89764 8.00787Z" fill="white"/>
    <path d="M11.5669 9.7874V10.6773L10.6772 10.6772V9.78749L11.5669 9.7874Z" fill="white"/>
    <path d="M1.77953 6.22844V7.11811H0.889764L0.889764 6.22844H1.77953Z" fill="white"/>
    <path d="M4.44882 8.00797V8.89764H3.55905V8.00797H4.44882Z" fill="white"/>
    <path d="M7.11811 9.78749V10.6772H6.22835V9.78749H7.11811Z" fill="white"/>
    <path d="M32.0313 9.25908e-05V0.889764L32.9211 0.889669V0L32.0313 9.25908e-05Z" fill="white"/>
    <path d="M32.0313 0.889764V1.77953L32.9211 1.77943V0.889669L32.0313 0.889764Z" fill="white"/>
    <path d="M32.0313 1.77953V2.66929L32.9211 2.6692V1.77943L32.0313 1.77953Z" fill="white"/>
    <path d="M31.1415 1.77962V2.66929H32.0313V1.77953L31.1415 1.77962Z" fill="white"/>
    <path d="M32.9211 1.77943V2.6692H33.8108V1.77953L32.9211 1.77943Z" fill="white"/>
    <path d="M32.9211 2.6692V3.55905L33.8108 3.55896V2.6692H32.9211Z" fill="white"/>
    <path d="M31.1415 2.66929V3.55905L32.0313 3.55896V2.66929H31.1415Z" fill="white"/>
    <path d="M32.0313 2.66929V3.55896L32.9211 3.55905V2.6692L32.0313 2.66929Z" fill="white"/>
    <path d="M31.1415 3.55905V4.44882L32.0313 4.44872V3.55896L31.1415 3.55905Z" fill="white"/>
    <path d="M32.0313 3.55896V4.44872H32.9211V3.55905L32.0313 3.55896Z" fill="white"/>
    <path d="M33.8108 3.55896V4.44882L32.9211 4.44872V3.55905L33.8108 3.55896Z" fill="white"/>
    <path d="M30.2518 4.44891V5.33858L31.1415 5.33849V4.44882L30.2518 4.44891Z" fill="white"/>
    <path d="M30.2518 5.33858V6.22835L31.1415 6.22825V5.33849L30.2518 5.33858Z" fill="white"/>
    <path d="M26.6927 6.22844V7.11811L27.5825 7.11801V6.22835L26.6927 6.22844Z" fill="white"/>
    <path d="M25.803 6.22844V7.11811H26.6927V6.22844H25.803Z" fill="white"/>
    <path d="M28.4723 8.00797V8.89764L29.362 8.89754V8.00787L28.4723 8.00797Z" fill="white"/>
    <path d="M31.1415 9.78749V10.6772L32.0313 10.6771V9.7874L31.1415 9.78749Z" fill="white"/>
    <path d="M31.1415 4.44882V5.33849H32.0313V4.44872L31.1415 4.44882Z" fill="white"/>
    <path d="M31.1415 5.33849V6.22825H32.0313V5.33849H31.1415Z" fill="white"/>
    <path d="M31.1415 6.22825V7.11811L32.0313 7.11801V6.22825H31.1415Z" fill="white"/>
    <path d="M33.8108 8.00797V8.89764L34.7006 8.89754V8.00787L33.8108 8.00797Z" fill="white"/>
    <path d="M27.5825 6.22835V7.11801H28.4723V6.22835H27.5825Z" fill="white"/>
    <path d="M26.6927 6.22844V7.11811L27.5825 7.11801V6.22835L26.6927 6.22844Z" fill="white"/>
    <path d="M29.362 8.00787V8.89754H30.2518V8.00787H29.362Z" fill="white"/>
    <path d="M32.0313 9.7874V10.6771H32.9211V9.7874H32.0313Z" fill="white"/>
    <path d="M32.0313 4.44872V5.33849H32.9211V4.44872H32.0313Z" fill="white"/>
    <path d="M32.0313 5.33849V6.22825H32.9211V5.33849H32.0313Z" fill="white"/>
    <path d="M32.0313 6.22825V7.11801H32.9211V6.22825H32.0313Z" fill="white"/>
    <path d="M34.7006 8.00787V8.89754H35.5904V8.00787H34.7006Z" fill="white"/>
    <path d="M34.7006 6.22844V7.11811L35.5904 7.11801V6.22835L34.7006 6.22844Z" fill="white"/>
    <path d="M37.3699 6.22844V7.11811L38.2597 7.11801V6.22835L37.3699 6.22844Z" fill="white"/>
    <path d="M28.4723 11.567V12.4567L29.362 12.4566V11.5669L28.4723 11.567Z" fill="white"/>
    <path d="M31.1415 11.567V12.4567L32.0313 12.4566V11.5669L31.1415 11.567Z" fill="white"/>
    <path d="M33.8108 11.567V12.4567L34.7006 12.4566V11.5669L33.8108 11.567Z" fill="white"/>
    <path d="M34.7006 13.3465V14.2362L35.5904 14.2361V13.3465L34.7006 13.3465Z" fill="white"/>
    <path d="M27.5825 13.3465V14.2362L28.4723 14.2361V13.3465L27.5825 13.3465Z" fill="white"/>
    <path d="M28.4723 6.22835V7.11801H29.362V6.22835H28.4723Z" fill="white"/>
    <path d="M27.5825 6.22835V7.11801H28.4723V6.22835H27.5825Z" fill="white"/>
    <path d="M30.2518 8.00787V8.89754H31.1415V8.00787H30.2518Z" fill="white"/>
    <path d="M32.9211 9.7874V10.6771H33.8108V9.7874H32.9211Z" fill="white"/>
    <path d="M33.8108 4.44882V5.33858L32.9211 5.33849V4.44872L33.8108 4.44882Z" fill="white"/>
    <path d="M33.8108 5.33858V6.22835L32.9211 6.22825V5.33849L33.8108 5.33858Z" fill="white"/>
    <path d="M33.8108 6.22835V7.11811L32.9211 7.11801V6.22825L33.8108 6.22835Z" fill="white"/>
    <path d="M36.4801 8.00797V8.89764L35.5904 8.89754V8.00787L36.4801 8.00797Z" fill="white"/>
    <path d="M36.4801 6.22844V7.11811L35.5904 7.11801V6.22835L36.4801 6.22844Z" fill="white"/>
    <path d="M39.1494 6.22844V7.11811L38.2597 7.11801V6.22835L39.1494 6.22844Z" fill="white"/>
    <path d="M30.2518 11.567V12.4567L29.362 12.4566V11.5669L30.2518 11.567Z" fill="white"/>
    <path d="M32.9211 11.567V12.4567L32.0313 12.4566V11.5669L32.9211 11.567Z" fill="white"/>
    <path d="M35.5904 11.567V12.4567L34.7006 12.4566V11.5669L35.5904 11.567Z" fill="white"/>
    <path d="M36.4801 13.3465V14.2362L35.5904 14.2361V13.3465L36.4801 13.3465Z" fill="white"/>
    <path d="M29.362 13.3465V14.2362L28.4723 14.2361V13.3465L29.362 13.3465Z" fill="white"/>
    <path d="M30.2518 6.22835V7.11811L29.362 7.11801V6.22835H30.2518Z" fill="white"/>
    <path d="M31.1415 6.22825V7.11811H30.2518V6.22835L31.1415 6.22825Z" fill="white"/>
    <path d="M34.7006 6.22844V7.11811H33.8108V6.22835L34.7006 6.22844Z" fill="white"/>
    <path d="M29.362 6.22835V7.11801H28.4723V6.22835H29.362Z" fill="white"/>
    <path d="M32.0313 8.00797V8.89764L31.1415 8.89754V8.00787L32.0313 8.00797Z" fill="white"/>
    <path d="M34.7006 9.78749V10.6772L33.8108 10.6771V9.7874L34.7006 9.78749Z" fill="white"/>
    <path d="M24.9132 6.22844L24.9132 7.11811L24.0234 7.11801L24.0234 6.22835L24.9132 6.22844Z" fill="white"/>
    <path d="M27.5825 8.00797V8.89764L26.6927 8.89754V8.00787L27.5825 8.00797Z" fill="white"/>
    <path d="M30.2518 9.78749V10.6772L29.362 10.6771V9.7874L30.2518 9.78749Z" fill="white"/>
    <path d="M26.6927 8.00787V8.89754H25.803V8.00787H26.6927Z" fill="white"/>
    <path d="M29.362 9.7874V10.6771H28.4723V9.7874H29.362Z" fill="white"/>
    <path d="M25.803 7.11811V8.00787L24.9132 8.00778V7.11811H25.803Z" fill="white"/>
    <path d="M28.4723 8.89764V9.7874L27.5825 9.78731V8.89764H28.4723Z" fill="white"/>
    <path d="M31.1415 10.6772V11.567H30.2518V10.6772H31.1415Z" fill="white"/>
    <path d="M30.2518 10.6772V11.567L29.362 11.5669V10.6771L30.2518 10.6772Z" fill="white"/>
    <path d="M26.6927 7.11811V8.00787H25.803V7.11811H26.6927Z" fill="white"/>
    <path d="M29.362 8.89754V9.7874H28.4723V8.89764L29.362 8.89754Z" fill="white"/>
    <path d="M32.0313 10.6771V11.5669L31.1415 11.567V10.6772L32.0313 10.6771Z" fill="white"/>
    <path d="M28.4723 7.11801V8.00797H27.5825V7.11801H28.4723Z" fill="white"/>
    <path d="M31.1415 8.89754V9.78749H30.2518V8.89754H31.1415Z" fill="white"/>
    <path d="M33.8108 10.6771V11.567H32.9211V10.6771H33.8108Z" fill="white"/>
    <path d="M27.5825 7.11801V8.00797L26.6927 8.00787V7.11811L27.5825 7.11801Z" fill="white"/>
    <path d="M30.2518 8.89754V9.78749L29.362 9.7874V8.89754H30.2518Z" fill="white"/>
    <path d="M32.9211 10.6771V11.567L32.0313 11.5669V10.6771H32.9211Z" fill="white"/>
    <path d="M29.362 7.11801V8.00787L28.4723 8.00797V7.11801H29.362Z" fill="white"/>
    <path d="M32.0313 8.89764V9.7874L31.1415 9.78749V8.89754L32.0313 8.89764Z" fill="white"/>
    <path d="M34.7006 10.6772V11.5669L33.8108 11.567V10.6771L34.7006 10.6772Z" fill="white"/>
    <path d="M30.2518 7.11811V8.00787H29.362V7.11801L30.2518 7.11811Z" fill="white"/>
    <path d="M32.9211 8.89773V9.7874H32.0313V8.89764L32.9211 8.89773Z" fill="white"/>
    <path d="M35.5904 10.6773V11.567L34.7006 11.5669V10.6772L35.5904 10.6773Z" fill="white"/>
    <path d="M31.1415 7.11811V8.00787H30.2518V7.11811H31.1415Z" fill="white"/>
    <path d="M33.8108 8.89764V9.7874H32.9211V8.89773L33.8108 8.89764Z" fill="white"/>
    <path d="M36.4801 9.78749V10.6772L35.5904 10.6773V9.7874L36.4801 9.78749Z" fill="white"/>
    <path d="M32.0313 7.11801V8.00797L31.1415 8.00787V7.11811L32.0313 7.11801Z" fill="white"/>
    <path d="M34.7006 8.89754V9.78749L33.8108 9.7874V8.89764L34.7006 8.89754Z" fill="white"/>
    <path d="M32.9211 7.11801V8.00787L32.0313 8.00797V7.11801H32.9211Z" fill="white"/>
    <path d="M35.5904 8.89754V9.7874L34.7006 9.78749V8.89754H35.5904Z" fill="white"/>
    <path d="M35.5904 7.11801V8.00787H34.7006V7.11811L35.5904 7.11801Z" fill="white"/>
    <path d="M38.2597 7.11801V8.00787L37.3699 8.00778V7.11811L38.2597 7.11801Z" fill="white"/>
    <path d="M29.362 12.4566V13.3465L28.4723 13.3465V12.4567L29.362 12.4566Z" fill="white"/>
    <path d="M32.0313 12.4566V13.3465L31.1415 13.3464V12.4567L32.0313 12.4566Z" fill="white"/>
    <path d="M34.7006 12.4566V13.3465L33.8108 13.3464V12.4567L34.7006 12.4566Z" fill="white"/>
    <path d="M35.5904 14.2361V15.126L34.7006 15.1259V14.2362L35.5904 14.2361Z" fill="white"/>
    <path d="M34.7006 13.3465V14.2362L33.8108 14.2361V13.3464L34.7006 13.3465Z" fill="white"/>
    <path d="M28.4723 14.2361V15.126L27.5825 15.1259V14.2362L28.4723 14.2361Z" fill="white"/>
    <path d="M28.4723 15.126V16.0157L27.5825 16.0157V15.1259L28.4723 15.126Z" fill="white"/>
    <path d="M37.3699 15.1261V16.0157L36.4801 16.0157V15.126L37.3699 15.1261Z" fill="white"/>
    <path d="M37.3699 16.0157V16.9055L36.4801 16.9054V16.0157L37.3699 16.0157Z" fill="white"/>
    <path d="M27.5825 15.1259V16.0157H26.6927V15.126L27.5825 15.1259Z" fill="white"/>
    <path d="M36.4801 15.126V16.0157H35.5904V15.126H36.4801Z" fill="white"/>
    <path d="M27.5825 16.0157V16.9055L26.6927 16.9054V16.0157H27.5825Z" fill="white"/>
    <path d="M26.6927 16.9054V17.7953L25.803 17.7952V16.9055L26.6927 16.9054Z" fill="white"/>
    <path d="M28.4723 16.0157V16.9055H27.5825V16.0157L28.4723 16.0157Z" fill="white"/>
    <path d="M29.362 15.1261V16.0157H28.4723V15.126L29.362 15.1261Z" fill="white"/>
    <path d="M38.2597 15.1261V16.0157H37.3699V15.1261H38.2597Z" fill="white"/>
    <path d="M38.2597 16.0157V16.9055H37.3699V16.0157H38.2597Z" fill="white"/>
    <path d="M39.1494 16.9056V17.7953L38.2597 17.7952V16.9055L39.1494 16.9056Z" fill="white"/>
    <path d="M33.8108 7.11811V8.00797L32.9211 8.00787V7.11801L33.8108 7.11811Z" fill="white"/>
    <path d="M36.4801 8.89764V9.78749L35.5904 9.7874V8.89754L36.4801 8.89764Z" fill="white"/>
    <path d="M36.4801 7.11811V8.00797L35.5904 8.00787V7.11801L36.4801 7.11811Z" fill="white"/>
    <path d="M39.1494 7.11811V8.00787H38.2597V7.11801L39.1494 7.11811Z" fill="white"/>
    <path d="M39.1494 8.00787V8.89764L38.2597 8.89754V8.00787H39.1494Z" fill="white"/>
    <path d="M38.2597 8.00787V8.89754H37.3699V8.00778L38.2597 8.00787Z" fill="white"/>
    <path d="M40.0392 7.1182V8.00787H39.1494V7.11811L40.0392 7.1182Z" fill="white"/>
    <path d="M30.2518 12.4567V13.3465L29.362 13.3465V12.4566L30.2518 12.4567Z" fill="white"/>
    <path d="M32.9211 12.4567V13.3465H32.0313V12.4566L32.9211 12.4567Z" fill="white"/>
    <path d="M35.5904 12.4567V13.3465L34.7006 13.3465V12.4566L35.5904 12.4567Z" fill="white"/>
    <path d="M36.4801 14.2362V15.126H35.5904V14.2361L36.4801 14.2362Z" fill="white"/>
    <path d="M29.362 14.2362V15.1261L28.4723 15.126V14.2361L29.362 14.2362Z" fill="white"/>
    <path d="M30.2518 14.2363V15.126L29.362 15.1261V14.2362L30.2518 14.2363Z" fill="white"/>
    <path d="M34.7006 7.11811V8.00787L33.8108 8.00797V7.11811H34.7006Z" fill="white"/>
    <path d="M37.3699 8.89754V9.7874L36.4801 9.78749V8.89764L37.3699 8.89754Z" fill="white"/>
    <path d="M37.3699 7.11811V8.00778L36.4801 8.00797V7.11811H37.3699Z" fill="white"/>
    <path d="M40.9289 6.22844V7.11811L40.0392 7.1182V6.22835L40.9289 6.22844Z" fill="white"/>
    <path d="M31.1415 12.4567V13.3464L30.2518 13.3465V12.4567H31.1415Z" fill="white"/>
    <path d="M33.8108 12.4567V13.3464L32.9211 13.3465V12.4567H33.8108Z" fill="white"/>
    <path d="M36.4801 12.4568V13.3465L35.5904 13.3465V12.4567L36.4801 12.4568Z" fill="white"/>
    <path d="M37.3699 14.2363V15.1261L36.4801 15.126V14.2362L37.3699 14.2363Z" fill="white"/>
    <path d="M31.1415 13.3464V14.2362L30.2518 14.2363V13.3465L31.1415 13.3464Z" fill="white"/>
    <path d="M34.7006 4.44891V5.33858H33.8108V4.44882L34.7006 4.44891Z" fill="white"/>
    <path d="M34.7006 5.33858V6.22844L33.8108 6.22835V5.33858H34.7006Z" fill="white"/>
    <path d="M37.3699 8.00778V8.89754L36.4801 8.89764V8.00797L37.3699 8.00778Z" fill="white"/>
    <path d="M37.3699 6.22844V7.11811H36.4801V6.22844H37.3699Z" fill="white"/>
    <path d="M40.0392 6.22835V7.1182L39.1494 7.11811V6.22844L40.0392 6.22835Z" fill="white"/>
    <path d="M31.1415 11.567V12.4567H30.2518V11.567H31.1415Z" fill="white"/>
    <path d="M33.8108 11.567V12.4567H32.9211V11.567H33.8108Z" fill="white"/>
    <path d="M36.4801 11.567V12.4568L35.5904 12.4567V11.567H36.4801Z" fill="white"/>
    <path d="M37.3699 13.3465V14.2363L36.4801 14.2362V13.3465H37.3699Z" fill="white"/>
    <path d="M30.2518 13.3465V14.2363L29.362 14.2362V13.3465L30.2518 13.3465Z" fill="white"/>
    <path d="M33.8108 8.00797V8.89764L32.9211 8.89773V8.00787L33.8108 8.00797Z" fill="white"/>
    <path d="M32.9211 8.00787V8.89773L32.0313 8.89764V8.00797L32.9211 8.00787Z" fill="white"/>
    <path d="M35.5904 9.7874V10.6773L34.7006 10.6772V9.78749L35.5904 9.7874Z" fill="white"/>
    <path d="M25.803 6.22844V7.11811H24.9132L24.9132 6.22844H25.803Z" fill="white"/>
    <path d="M28.4723 8.00797V8.89764H27.5825V8.00797H28.4723Z" fill="white"/>
    <path d="M31.1415 9.78749V10.6772H30.2518V9.78749H31.1415Z" fill="white"/>
    <path d="M56.0547 9.25908e-05V0.889764L56.9445 0.889669V0L56.0547 9.25908e-05Z" fill="white"/>
    <path d="M56.0547 0.889764V1.77953L56.9445 1.77943V0.889669L56.0547 0.889764Z" fill="white"/>
    <path d="M56.0547 1.77953V2.66929L56.9445 2.6692V1.77943L56.0547 1.77953Z" fill="white"/>
    <path d="M55.165 1.77962V2.66929H56.0547V1.77953L55.165 1.77962Z" fill="white"/>
    <path d="M56.9445 1.77943V2.6692H57.8343V1.77953L56.9445 1.77943Z" fill="white"/>
    <path d="M56.9445 2.6692V3.55905L57.8343 3.55896V2.6692H56.9445Z" fill="white"/>
    <path d="M55.165 2.66929V3.55905L56.0547 3.55896V2.66929H55.165Z" fill="white"/>
    <path d="M56.0547 2.66929V3.55896L56.9445 3.55905V2.6692L56.0547 2.66929Z" fill="white"/>
    <path d="M55.165 3.55905V4.44882L56.0547 4.44872V3.55896L55.165 3.55905Z" fill="white"/>
    <path d="M56.0547 3.55896V4.44872H56.9445V3.55905L56.0547 3.55896Z" fill="white"/>
    <path d="M57.8343 3.55896V4.44882L56.9445 4.44872V3.55905L57.8343 3.55896Z" fill="white"/>
    <path d="M54.2752 4.44891V5.33858L55.165 5.33849V4.44882L54.2752 4.44891Z" fill="white"/>
    <path d="M54.2752 5.33858V6.22835L55.165 6.22825V5.33849L54.2752 5.33858Z" fill="white"/>
    <path d="M50.7162 6.22844V7.11811L51.6059 7.11801V6.22835L50.7162 6.22844Z" fill="white"/>
    <path d="M49.8264 6.22844V7.11811H50.7162V6.22844H49.8264Z" fill="white"/>
    <path d="M52.4957 8.00797V8.89764L53.3855 8.89754V8.00787L52.4957 8.00797Z" fill="white"/>
    <path d="M55.165 9.78749V10.6772L56.0547 10.6771V9.7874L55.165 9.78749Z" fill="white"/>
    <path d="M55.165 4.44882V5.33849H56.0547V4.44872L55.165 4.44882Z" fill="white"/>
    <path d="M55.165 5.33849V6.22825H56.0547V5.33849H55.165Z" fill="white"/>
    <path d="M55.165 6.22825V7.11811L56.0547 7.11801V6.22825H55.165Z" fill="white"/>
    <path d="M57.8343 8.00797V8.89764L58.724 8.89754V8.00787L57.8343 8.00797Z" fill="white"/>
    <path d="M51.6059 6.22835V7.11801H52.4957V6.22835H51.6059Z" fill="white"/>
    <path d="M50.7162 6.22844V7.11811L51.6059 7.11801V6.22835L50.7162 6.22844Z" fill="white"/>
    <path d="M53.3855 8.00787V8.89754H54.2752V8.00787H53.3855Z" fill="white"/>
    <path d="M56.0547 9.7874V10.6771H56.9445V9.7874H56.0547Z" fill="white"/>
    <path d="M56.0547 4.44872V5.33849H56.9445V4.44872H56.0547Z" fill="white"/>
    <path d="M56.0547 5.33849V6.22825H56.9445V5.33849H56.0547Z" fill="white"/>
    <path d="M56.0547 6.22825V7.11801H56.9445V6.22825H56.0547Z" fill="white"/>
    <path d="M58.724 8.00787V8.89754H59.6138V8.00787H58.724Z" fill="white"/>
    <path d="M58.724 6.22844V7.11811L59.6138 7.11801V6.22835L58.724 6.22844Z" fill="white"/>
    <path d="M61.3933 6.22844V7.11811L62.2831 7.11801V6.22835L61.3933 6.22844Z" fill="white"/>
    <path d="M52.4957 11.567V12.4567L53.3855 12.4566V11.5669L52.4957 11.567Z" fill="white"/>
    <path d="M55.165 11.567V12.4567L56.0547 12.4566V11.5669L55.165 11.567Z" fill="white"/>
    <path d="M57.8343 11.567V12.4567L58.724 12.4566V11.5669L57.8343 11.567Z" fill="white"/>
    <path d="M58.724 13.3465V14.2362L59.6138 14.2361V13.3465L58.724 13.3465Z" fill="white"/>
    <path d="M51.6059 13.3465V14.2362L52.4957 14.2361V13.3465L51.6059 13.3465Z" fill="white"/>
    <path d="M52.4957 6.22835V7.11801H53.3855V6.22835H52.4957Z" fill="white"/>
    <path d="M51.6059 6.22835V7.11801H52.4957V6.22835H51.6059Z" fill="white"/>
    <path d="M54.2752 8.00787V8.89754H55.165V8.00787H54.2752Z" fill="white"/>
    <path d="M56.9445 9.7874V10.6771H57.8343V9.7874H56.9445Z" fill="white"/>
    <path d="M57.8343 4.44882V5.33858L56.9445 5.33849V4.44872L57.8343 4.44882Z" fill="white"/>
    <path d="M57.8343 5.33858V6.22835L56.9445 6.22825V5.33849L57.8343 5.33858Z" fill="white"/>
    <path d="M57.8343 6.22835V7.11811L56.9445 7.11801V6.22825L57.8343 6.22835Z" fill="white"/>
    <path d="M60.5036 8.00797V8.89764L59.6138 8.89754V8.00787L60.5036 8.00797Z" fill="white"/>
    <path d="M60.5036 6.22844V7.11811L59.6138 7.11801V6.22835L60.5036 6.22844Z" fill="white"/>
    <path d="M63.1729 6.22844V7.11811L62.2831 7.11801V6.22835L63.1729 6.22844Z" fill="white"/>
    <path d="M54.2752 11.567V12.4567L53.3855 12.4566V11.5669L54.2752 11.567Z" fill="white"/>
    <path d="M56.9445 11.567V12.4567L56.0547 12.4566V11.5669L56.9445 11.567Z" fill="white"/>
    <path d="M59.6138 11.567V12.4567L58.724 12.4566V11.5669L59.6138 11.567Z" fill="white"/>
    <path d="M60.5036 13.3465V14.2362L59.6138 14.2361V13.3465L60.5036 13.3465Z" fill="white"/>
    <path d="M53.3855 13.3465V14.2362L52.4957 14.2361V13.3465L53.3855 13.3465Z" fill="white"/>
    <path d="M54.2752 6.22835V7.11811L53.3855 7.11801V6.22835H54.2752Z" fill="white"/>
    <path d="M55.165 6.22825V7.11811H54.2752V6.22835L55.165 6.22825Z" fill="white"/>
    <path d="M58.724 6.22844V7.11811H57.8343V6.22835L58.724 6.22844Z" fill="white"/>
    <path d="M53.3855 6.22835V7.11801H52.4957V6.22835H53.3855Z" fill="white"/>
    <path d="M56.0547 8.00797V8.89764L55.165 8.89754V8.00787L56.0547 8.00797Z" fill="white"/>
    <path d="M58.724 9.78749V10.6772L57.8343 10.6771V9.7874L58.724 9.78749Z" fill="white"/>
    <path d="M48.9366 6.22844L48.9366 7.11811L48.0469 7.11801L48.0469 6.22835L48.9366 6.22844Z" fill="white"/>
    <path d="M51.6059 8.00797V8.89764L50.7162 8.89754V8.00787L51.6059 8.00797Z" fill="white"/>
    <path d="M54.2752 9.78749V10.6772L53.3855 10.6771V9.7874L54.2752 9.78749Z" fill="white"/>
    <path d="M50.7162 8.00787V8.89754H49.8264V8.00787H50.7162Z" fill="white"/>
    <path d="M53.3855 9.7874V10.6771H52.4957V9.7874H53.3855Z" fill="white"/>
    <path d="M49.8264 7.11811V8.00787L48.9366 8.00778V7.11811H49.8264Z" fill="white"/>
    <path d="M52.4957 8.89764V9.7874L51.6059 9.78731V8.89764H52.4957Z" fill="white"/>
    <path d="M55.165 10.6772V11.567H54.2752V10.6772H55.165Z" fill="white"/>
    <path d="M54.2752 10.6772V11.567L53.3855 11.5669V10.6771L54.2752 10.6772Z" fill="white"/>
    <path d="M50.7162 7.11811V8.00787H49.8264V7.11811H50.7162Z" fill="white"/>
    <path d="M53.3855 8.89754V9.7874H52.4957V8.89764L53.3855 8.89754Z" fill="white"/>
    <path d="M56.0547 10.6771V11.5669L55.165 11.567V10.6772L56.0547 10.6771Z" fill="white"/>
    <path d="M52.4957 7.11801V8.00797H51.6059V7.11801H52.4957Z" fill="white"/>
    <path d="M55.165 8.89754V9.78749H54.2752V8.89754H55.165Z" fill="white"/>
    <path d="M57.8343 10.6771V11.567H56.9445V10.6771H57.8343Z" fill="white"/>
    <path d="M51.6059 7.11801V8.00797L50.7162 8.00787V7.11811L51.6059 7.11801Z" fill="white"/>
    <path d="M54.2752 8.89754V9.78749L53.3855 9.7874V8.89754H54.2752Z" fill="white"/>
    <path d="M56.9445 10.6771V11.567L56.0547 11.5669V10.6771H56.9445Z" fill="white"/>
    <path d="M53.3855 7.11801V8.00787L52.4957 8.00797V7.11801H53.3855Z" fill="white"/>
    <path d="M56.0547 8.89764V9.7874L55.165 9.78749V8.89754L56.0547 8.89764Z" fill="white"/>
    <path d="M58.724 10.6772V11.5669L57.8343 11.567V10.6771L58.724 10.6772Z" fill="white"/>
    <path d="M54.2752 7.11811V8.00787H53.3855V7.11801L54.2752 7.11811Z" fill="white"/>
    <path d="M56.9445 8.89773V9.7874H56.0547V8.89764L56.9445 8.89773Z" fill="white"/>
    <path d="M59.6138 10.6773V11.567L58.724 11.5669V10.6772L59.6138 10.6773Z" fill="white"/>
    <path d="M55.165 7.11811V8.00787H54.2752V7.11811H55.165Z" fill="white"/>
    <path d="M57.8343 8.89764V9.7874H56.9445V8.89773L57.8343 8.89764Z" fill="white"/>
    <path d="M60.5036 9.78749V10.6772L59.6138 10.6773V9.7874L60.5036 9.78749Z" fill="white"/>
    <path d="M56.0547 7.11801V8.00797L55.165 8.00787V7.11811L56.0547 7.11801Z" fill="white"/>
    <path d="M58.724 8.89754V9.78749L57.8343 9.7874V8.89764L58.724 8.89754Z" fill="white"/>
    <path d="M56.9445 7.11801V8.00787L56.0547 8.00797V7.11801H56.9445Z" fill="white"/>
    <path d="M59.6138 8.89754V9.7874L58.724 9.78749V8.89754H59.6138Z" fill="white"/>
    <path d="M59.6138 7.11801V8.00787H58.724V7.11811L59.6138 7.11801Z" fill="white"/>
    <path d="M62.2831 7.11801V8.00787L61.3933 8.00778V7.11811L62.2831 7.11801Z" fill="white"/>
    <path d="M53.3855 12.4566V13.3465L52.4957 13.3465V12.4567L53.3855 12.4566Z" fill="white"/>
    <path d="M56.0547 12.4566V13.3465L55.165 13.3464V12.4567L56.0547 12.4566Z" fill="white"/>
    <path d="M58.724 12.4566V13.3465L57.8343 13.3464V12.4567L58.724 12.4566Z" fill="white"/>
    <path d="M59.6138 14.2361V15.126L58.724 15.1259V14.2362L59.6138 14.2361Z" fill="white"/>
    <path d="M58.724 13.3465V14.2362L57.8343 14.2361V13.3464L58.724 13.3465Z" fill="white"/>
    <path d="M52.4957 14.2361V15.126L51.6059 15.1259V14.2362L52.4957 14.2361Z" fill="white"/>
    <path d="M52.4957 15.126V16.0157L51.6059 16.0157V15.1259L52.4957 15.126Z" fill="white"/>
    <path d="M61.3933 15.1261V16.0157L60.5036 16.0157V15.126L61.3933 15.1261Z" fill="white"/>
    <path d="M61.3933 16.0157V16.9055L60.5036 16.9054V16.0157L61.3933 16.0157Z" fill="white"/>
    <path d="M51.6059 15.1259V16.0157H50.7162V15.126L51.6059 15.1259Z" fill="white"/>
    <path d="M60.5036 15.126V16.0157H59.6138V15.126H60.5036Z" fill="white"/>
    <path d="M51.6059 16.0157V16.9055L50.7162 16.9054V16.0157H51.6059Z" fill="white"/>
    <path d="M50.7162 16.9054V17.7953L49.8264 17.7952V16.9055L50.7162 16.9054Z" fill="white"/>
    <path d="M52.4957 16.0157V16.9055H51.6059V16.0157L52.4957 16.0157Z" fill="white"/>
    <path d="M53.3855 15.1261V16.0157H52.4957V15.126L53.3855 15.1261Z" fill="white"/>
    <path d="M62.2831 15.1261V16.0157H61.3933V15.1261H62.2831Z" fill="white"/>
    <path d="M62.2831 16.0157V16.9055H61.3933V16.0157H62.2831Z" fill="white"/>
    <path d="M63.1729 16.9056V17.7953L62.2831 17.7952V16.9055L63.1729 16.9056Z" fill="white"/>
    <path d="M57.8343 7.11811V8.00797L56.9445 8.00787V7.11801L57.8343 7.11811Z" fill="white"/>
    <path d="M60.5036 8.89764V9.78749L59.6138 9.7874V8.89754L60.5036 8.89764Z" fill="white"/>
    <path d="M60.5036 7.11811V8.00797L59.6138 8.00787V7.11801L60.5036 7.11811Z" fill="white"/>
    <path d="M63.1729 7.11811V8.00787H62.2831V7.11801L63.1729 7.11811Z" fill="white"/>
    <path d="M63.1729 8.00787V8.89764L62.2831 8.89754V8.00787H63.1729Z" fill="white"/>
    <path d="M62.2831 8.00787V8.89754H61.3933V8.00778L62.2831 8.00787Z" fill="white"/>
    <path d="M64.0626 7.1182V8.00787H63.1729V7.11811L64.0626 7.1182Z" fill="white"/>
    <path d="M54.2752 12.4567V13.3465L53.3855 13.3465V12.4566L54.2752 12.4567Z" fill="white"/>
    <path d="M56.9445 12.4567V13.3465H56.0547V12.4566L56.9445 12.4567Z" fill="white"/>
    <path d="M59.6138 12.4567V13.3465L58.724 13.3465V12.4566L59.6138 12.4567Z" fill="white"/>
    <path d="M60.5036 14.2362V15.126H59.6138V14.2361L60.5036 14.2362Z" fill="white"/>
    <path d="M53.3855 14.2362V15.1261L52.4957 15.126V14.2361L53.3855 14.2362Z" fill="white"/>
    <path d="M54.2752 14.2363V15.126L53.3855 15.1261V14.2362L54.2752 14.2363Z" fill="white"/>
    <path d="M58.724 7.11811V8.00787L57.8343 8.00797V7.11811H58.724Z" fill="white"/>
    <path d="M61.3933 8.89754V9.7874L60.5036 9.78749V8.89764L61.3933 8.89754Z" fill="white"/>
    <path d="M61.3933 7.11811V8.00778L60.5036 8.00797V7.11811H61.3933Z" fill="white"/>
    <path d="M64.9524 6.22844V7.11811L64.0626 7.1182V6.22835L64.9524 6.22844Z" fill="white"/>
    <path d="M55.165 12.4567V13.3464L54.2752 13.3465V12.4567H55.165Z" fill="white"/>
    <path d="M57.8343 12.4567V13.3464L56.9445 13.3465V12.4567H57.8343Z" fill="white"/>
    <path d="M60.5036 12.4568V13.3465L59.6138 13.3465V12.4567L60.5036 12.4568Z" fill="white"/>
    <path d="M61.3933 14.2363V15.1261L60.5036 15.126V14.2362L61.3933 14.2363Z" fill="white"/>
    <path d="M55.165 13.3464V14.2362L54.2752 14.2363V13.3465L55.165 13.3464Z" fill="white"/>
    <path d="M58.724 4.44891V5.33858H57.8343V4.44882L58.724 4.44891Z" fill="white"/>
    <path d="M58.724 5.33858V6.22844L57.8343 6.22835V5.33858H58.724Z" fill="white"/>
    <path d="M61.3933 8.00778V8.89754L60.5036 8.89764V8.00797L61.3933 8.00778Z" fill="white"/>
    <path d="M61.3933 6.22844V7.11811H60.5036V6.22844H61.3933Z" fill="white"/>
    <path d="M64.0626 6.22835V7.1182L63.1729 7.11811V6.22844L64.0626 6.22835Z" fill="white"/>
    <path d="M55.165 11.567V12.4567H54.2752V11.567H55.165Z" fill="white"/>
    <path d="M57.8343 11.567V12.4567H56.9445V11.567H57.8343Z" fill="white"/>
    <path d="M60.5036 11.567V12.4568L59.6138 12.4567V11.567H60.5036Z" fill="white"/>
    <path d="M61.3933 13.3465V14.2363L60.5036 14.2362V13.3465H61.3933Z" fill="white"/>
    <path d="M54.2752 13.3465V14.2363L53.3855 14.2362V13.3465L54.2752 13.3465Z" fill="white"/>
    <path d="M57.8343 8.00797V8.89764L56.9445 8.89773V8.00787L57.8343 8.00797Z" fill="white"/>
    <path d="M56.9445 8.00787V8.89773L56.0547 8.89764V8.00797L56.9445 8.00787Z" fill="white"/>
    <path d="M59.6138 9.7874V10.6773L58.724 10.6772V9.78749L59.6138 9.7874Z" fill="white"/>
    <path d="M49.8264 6.22844V7.11811H48.9366L48.9366 6.22844H49.8264Z" fill="white"/>
    <path d="M52.4957 8.00797V8.89764H51.6059V8.00797H52.4957Z" fill="white"/>
    <path d="M55.165 9.78749V10.6772H54.2752V9.78749H55.165Z" fill="white"/>
    <path d="M80.0782 9.25908e-05V0.889764L80.9679 0.889669V0L80.0782 9.25908e-05Z" fill="white"/>
    <path d="M80.0782 0.889764V1.77953L80.9679 1.77943V0.889669L80.0782 0.889764Z" fill="white"/>
    <path d="M80.0782 1.77953V2.66929L80.9679 2.6692V1.77943L80.0782 1.77953Z" fill="white"/>
    <path d="M79.1884 1.77962V2.66929H80.0782V1.77953L79.1884 1.77962Z" fill="white"/>
    <path d="M80.9679 1.77943V2.6692H81.8577V1.77953L80.9679 1.77943Z" fill="white"/>
    <path d="M80.9679 2.6692V3.55905L81.8577 3.55896V2.6692H80.9679Z" fill="white"/>
    <path d="M79.1884 2.66929V3.55905L80.0782 3.55896V2.66929H79.1884Z" fill="white"/>
    <path d="M80.0782 2.66929V3.55896L80.9679 3.55905V2.6692L80.0782 2.66929Z" fill="white"/>
    <path d="M79.1884 3.55905V4.44882L80.0782 4.44872V3.55896L79.1884 3.55905Z" fill="white"/>
    <path d="M80.0782 3.55896V4.44872H80.9679V3.55905L80.0782 3.55896Z" fill="white"/>
    <path d="M81.8577 3.55896V4.44882L80.9679 4.44872V3.55905L81.8577 3.55896Z" fill="white"/>
    <path d="M78.2987 4.44891V5.33858L79.1884 5.33849V4.44882L78.2987 4.44891Z" fill="white"/>
    <path d="M78.2987 5.33858V6.22835L79.1884 6.22825V5.33849L78.2987 5.33858Z" fill="white"/>
    <path d="M74.7396 6.22844V7.11811L75.6294 7.11801V6.22835L74.7396 6.22844Z" fill="white"/>
    <path d="M73.8498 6.22844V7.11811H74.7396V6.22844H73.8498Z" fill="white"/>
    <path d="M76.5191 8.00797V8.89764L77.4089 8.89754V8.00787L76.5191 8.00797Z" fill="white"/>
    <path d="M79.1884 9.78749V10.6772L80.0782 10.6771V9.7874L79.1884 9.78749Z" fill="white"/>
    <path d="M79.1884 4.44882V5.33849H80.0782V4.44872L79.1884 4.44882Z" fill="white"/>
    <path d="M79.1884 5.33849V6.22825H80.0782V5.33849H79.1884Z" fill="white"/>
    <path d="M79.1884 6.22825V7.11811L80.0782 7.11801V6.22825H79.1884Z" fill="white"/>
    <path d="M81.8577 8.00797V8.89764L82.7475 8.89754V8.00787L81.8577 8.00797Z" fill="white"/>
    <path d="M75.6294 6.22835V7.11801H76.5191V6.22835H75.6294Z" fill="white"/>
    <path d="M74.7396 6.22844V7.11811L75.6294 7.11801V6.22835L74.7396 6.22844Z" fill="white"/>
    <path d="M77.4089 8.00787V8.89754H78.2987V8.00787H77.4089Z" fill="white"/>
    <path d="M80.0782 9.7874V10.6771H80.9679V9.7874H80.0782Z" fill="white"/>
    <path d="M80.0782 4.44872V5.33849H80.9679V4.44872H80.0782Z" fill="white"/>
    <path d="M80.0782 5.33849V6.22825H80.9679V5.33849H80.0782Z" fill="white"/>
    <path d="M80.0782 6.22825V7.11801H80.9679V6.22825H80.0782Z" fill="white"/>
    <path d="M82.7475 8.00787V8.89754H83.6372V8.00787H82.7475Z" fill="white"/>
    <path d="M82.7475 6.22844V7.11811L83.6372 7.11801V6.22835L82.7475 6.22844Z" fill="white"/>
    <path d="M85.4168 6.22844V7.11811L86.3065 7.11801V6.22835L85.4168 6.22844Z" fill="white"/>
    <path d="M76.5191 11.567V12.4567L77.4089 12.4566V11.5669L76.5191 11.567Z" fill="white"/>
    <path d="M79.1884 11.567V12.4567L80.0782 12.4566V11.5669L79.1884 11.567Z" fill="white"/>
    <path d="M81.8577 11.567V12.4567L82.7475 12.4566V11.5669L81.8577 11.567Z" fill="white"/>
    <path d="M82.7475 13.3465V14.2362L83.6372 14.2361V13.3465L82.7475 13.3465Z" fill="white"/>
    <path d="M75.6294 13.3465V14.2362L76.5191 14.2361V13.3465L75.6294 13.3465Z" fill="white"/>
    <path d="M76.5191 6.22835V7.11801H77.4089V6.22835H76.5191Z" fill="white"/>
    <path d="M75.6294 6.22835V7.11801H76.5191V6.22835H75.6294Z" fill="white"/>
    <path d="M78.2987 8.00787V8.89754H79.1884V8.00787H78.2987Z" fill="white"/>
    <path d="M80.9679 9.7874V10.6771H81.8577V9.7874H80.9679Z" fill="white"/>
    <path d="M81.8577 4.44882V5.33858L80.9679 5.33849V4.44872L81.8577 4.44882Z" fill="white"/>
    <path d="M81.8577 5.33858V6.22835L80.9679 6.22825V5.33849L81.8577 5.33858Z" fill="white"/>
    <path d="M81.8577 6.22835V7.11811L80.9679 7.11801V6.22825L81.8577 6.22835Z" fill="white"/>
    <path d="M84.527 8.00797V8.89764L83.6372 8.89754V8.00787L84.527 8.00797Z" fill="white"/>
    <path d="M84.527 6.22844V7.11811L83.6372 7.11801V6.22835L84.527 6.22844Z" fill="white"/>
    <path d="M87.1963 6.22844V7.11811L86.3065 7.11801V6.22835L87.1963 6.22844Z" fill="white"/>
    <path d="M78.2987 11.567V12.4567L77.4089 12.4566V11.5669L78.2987 11.567Z" fill="white"/>
    <path d="M80.9679 11.567V12.4567L80.0782 12.4566V11.5669L80.9679 11.567Z" fill="white"/>
    <path d="M83.6372 11.567V12.4567L82.7475 12.4566V11.5669L83.6372 11.567Z" fill="white"/>
    <path d="M84.527 13.3465V14.2362L83.6372 14.2361V13.3465L84.527 13.3465Z" fill="white"/>
    <path d="M77.4089 13.3465V14.2362L76.5191 14.2361V13.3465L77.4089 13.3465Z" fill="white"/>
    <path d="M78.2987 6.22835V7.11811L77.4089 7.11801V6.22835H78.2987Z" fill="white"/>
    <path d="M79.1884 6.22825V7.11811H78.2987V6.22835L79.1884 6.22825Z" fill="white"/>
    <path d="M82.7475 6.22844V7.11811H81.8577V6.22835L82.7475 6.22844Z" fill="white"/>
    <path d="M77.4089 6.22835V7.11801H76.5191V6.22835H77.4089Z" fill="white"/>
    <path d="M80.0782 8.00797V8.89764L79.1884 8.89754V8.00787L80.0782 8.00797Z" fill="white"/>
    <path d="M82.7475 9.78749V10.6772L81.8577 10.6771V9.7874L82.7475 9.78749Z" fill="white"/>
    <path d="M72.9601 6.22844L72.9601 7.11811L72.0703 7.11801L72.0703 6.22835L72.9601 6.22844Z" fill="white"/>
    <path d="M75.6294 8.00797V8.89764L74.7396 8.89754V8.00787L75.6294 8.00797Z" fill="white"/>
    <path d="M78.2987 9.78749V10.6772L77.4089 10.6771V9.7874L78.2987 9.78749Z" fill="white"/>
    <path d="M74.7396 8.00787V8.89754H73.8498V8.00787H74.7396Z" fill="white"/>
    <path d="M77.4089 9.7874V10.6771H76.5191V9.7874H77.4089Z" fill="white"/>
    <path d="M73.8498 7.11811V8.00787L72.9601 8.00778V7.11811H73.8498Z" fill="white"/>
    <path d="M76.5191 8.89764V9.7874L75.6294 9.78731V8.89764H76.5191Z" fill="white"/>
    <path d="M79.1884 10.6772V11.567H78.2987V10.6772H79.1884Z" fill="white"/>
    <path d="M78.2987 10.6772V11.567L77.4089 11.5669V10.6771L78.2987 10.6772Z" fill="white"/>
    <path d="M74.7396 7.11811V8.00787H73.8498V7.11811H74.7396Z" fill="white"/>
    <path d="M77.4089 8.89754V9.7874H76.5191V8.89764L77.4089 8.89754Z" fill="white"/>
    <path d="M80.0782 10.6771V11.5669L79.1884 11.567V10.6772L80.0782 10.6771Z" fill="white"/>
    <path d="M76.5191 7.11801V8.00797H75.6294V7.11801H76.5191Z" fill="white"/>
    <path d="M79.1884 8.89754V9.78749H78.2987V8.89754H79.1884Z" fill="white"/>
    <path d="M81.8577 10.6771V11.567H80.9679V10.6771H81.8577Z" fill="white"/>
    <path d="M75.6294 7.11801V8.00797L74.7396 8.00787V7.11811L75.6294 7.11801Z" fill="white"/>
    <path d="M78.2987 8.89754V9.78749L77.4089 9.7874V8.89754H78.2987Z" fill="white"/>
    <path d="M80.9679 10.6771V11.567L80.0782 11.5669V10.6771H80.9679Z" fill="white"/>
    <path d="M77.4089 7.11801V8.00787L76.5191 8.00797V7.11801H77.4089Z" fill="white"/>
    <path d="M80.0782 8.89764V9.7874L79.1884 9.78749V8.89754L80.0782 8.89764Z" fill="white"/>
    <path d="M82.7475 10.6772V11.5669L81.8577 11.567V10.6771L82.7475 10.6772Z" fill="white"/>
    <path d="M78.2987 7.11811V8.00787H77.4089V7.11801L78.2987 7.11811Z" fill="white"/>
    <path d="M80.9679 8.89773V9.7874H80.0782V8.89764L80.9679 8.89773Z" fill="white"/>
    <path d="M83.6372 10.6773V11.567L82.7475 11.5669V10.6772L83.6372 10.6773Z" fill="white"/>
    <path d="M79.1884 7.11811V8.00787H78.2987V7.11811H79.1884Z" fill="white"/>
    <path d="M81.8577 8.89764V9.7874H80.9679V8.89773L81.8577 8.89764Z" fill="white"/>
    <path d="M84.527 9.78749V10.6772L83.6372 10.6773V9.7874L84.527 9.78749Z" fill="white"/>
    <path d="M80.0782 7.11801V8.00797L79.1884 8.00787V7.11811L80.0782 7.11801Z" fill="white"/>
    <path d="M82.7475 8.89754V9.78749L81.8577 9.7874V8.89764L82.7475 8.89754Z" fill="white"/>
    <path d="M80.9679 7.11801V8.00787L80.0782 8.00797V7.11801H80.9679Z" fill="white"/>
    <path d="M83.6372 8.89754V9.7874L82.7475 9.78749V8.89754H83.6372Z" fill="white"/>
    <path d="M83.6372 7.11801V8.00787H82.7475V7.11811L83.6372 7.11801Z" fill="white"/>
    <path d="M86.3065 7.11801V8.00787L85.4168 8.00778V7.11811L86.3065 7.11801Z" fill="white"/>
    <path d="M77.4089 12.4566V13.3465L76.5191 13.3465V12.4567L77.4089 12.4566Z" fill="white"/>
    <path d="M80.0782 12.4566V13.3465L79.1884 13.3464V12.4567L80.0782 12.4566Z" fill="white"/>
    <path d="M82.7475 12.4566V13.3465L81.8577 13.3464V12.4567L82.7475 12.4566Z" fill="white"/>
    <path d="M83.6372 14.2361V15.126L82.7475 15.1259V14.2362L83.6372 14.2361Z" fill="white"/>
    <path d="M82.7475 13.3465V14.2362L81.8577 14.2361V13.3464L82.7475 13.3465Z" fill="white"/>
    <path d="M76.5191 14.2361V15.126L75.6294 15.1259V14.2362L76.5191 14.2361Z" fill="white"/>
    <path d="M76.5191 15.126V16.0157L75.6294 16.0157V15.1259L76.5191 15.126Z" fill="white"/>
    <path d="M85.4168 15.1261V16.0157L84.527 16.0157V15.126L85.4168 15.1261Z" fill="white"/>
    <path d="M85.4168 16.0157V16.9055L84.527 16.9054V16.0157L85.4168 16.0157Z" fill="white"/>
    <path d="M75.6294 15.1259V16.0157H74.7396V15.126L75.6294 15.1259Z" fill="white"/>
    <path d="M84.527 15.126V16.0157H83.6372V15.126H84.527Z" fill="white"/>
    <path d="M75.6294 16.0157V16.9055L74.7396 16.9054V16.0157H75.6294Z" fill="white"/>
    <path d="M74.7396 16.9054V17.7953L73.8498 17.7952V16.9055L74.7396 16.9054Z" fill="white"/>
    <path d="M76.5191 16.0157V16.9055H75.6294V16.0157L76.5191 16.0157Z" fill="white"/>
    <path d="M77.4089 15.1261V16.0157H76.5191V15.126L77.4089 15.1261Z" fill="white"/>
    <path d="M86.3065 15.1261V16.0157H85.4168V15.1261H86.3065Z" fill="white"/>
    <path d="M86.3065 16.0157V16.9055H85.4168V16.0157H86.3065Z" fill="white"/>
    <path d="M87.1963 16.9056V17.7953L86.3065 17.7952V16.9055L87.1963 16.9056Z" fill="white"/>
    <path d="M81.8577 7.11811V8.00797L80.9679 8.00787V7.11801L81.8577 7.11811Z" fill="white"/>
    <path d="M84.527 8.89764V9.78749L83.6372 9.7874V8.89754L84.527 8.89764Z" fill="white"/>
    <path d="M84.527 7.11811V8.00797L83.6372 8.00787V7.11801L84.527 7.11811Z" fill="white"/>
    <path d="M87.1963 7.11811V8.00787H86.3065V7.11801L87.1963 7.11811Z" fill="white"/>
    <path d="M87.1963 8.00787V8.89764L86.3065 8.89754V8.00787H87.1963Z" fill="white"/>
    <path d="M86.3065 8.00787V8.89754H85.4168V8.00778L86.3065 8.00787Z" fill="white"/>
    <path d="M88.0861 7.1182V8.00787H87.1963V7.11811L88.0861 7.1182Z" fill="white"/>
    <path d="M78.2987 12.4567V13.3465L77.4089 13.3465V12.4566L78.2987 12.4567Z" fill="white"/>
    <path d="M80.9679 12.4567V13.3465H80.0782V12.4566L80.9679 12.4567Z" fill="white"/>
    <path d="M83.6372 12.4567V13.3465L82.7475 13.3465V12.4566L83.6372 12.4567Z" fill="white"/>
    <path d="M84.527 14.2362V15.126H83.6372V14.2361L84.527 14.2362Z" fill="white"/>
    <path d="M77.4089 14.2362V15.1261L76.5191 15.126V14.2361L77.4089 14.2362Z" fill="white"/>
    <path d="M78.2987 14.2363V15.126L77.4089 15.1261V14.2362L78.2987 14.2363Z" fill="white"/>
    <path d="M82.7475 7.11811V8.00787L81.8577 8.00797V7.11811H82.7475Z" fill="white"/>
    <path d="M85.4168 8.89754V9.7874L84.527 9.78749V8.89764L85.4168 8.89754Z" fill="white"/>
    <path d="M85.4168 7.11811V8.00778L84.527 8.00797V7.11811H85.4168Z" fill="white"/>
    <path d="M88.9758 6.22844V7.11811L88.0861 7.1182V6.22835L88.9758 6.22844Z" fill="white"/>
    <path d="M79.1884 12.4567V13.3464L78.2987 13.3465V12.4567H79.1884Z" fill="white"/>
    <path d="M81.8577 12.4567V13.3464L80.9679 13.3465V12.4567H81.8577Z" fill="white"/>
    <path d="M84.527 12.4568V13.3465L83.6372 13.3465V12.4567L84.527 12.4568Z" fill="white"/>
    <path d="M85.4168 14.2363V15.1261L84.527 15.126V14.2362L85.4168 14.2363Z" fill="white"/>
    <path d="M79.1884 13.3464V14.2362L78.2987 14.2363V13.3465L79.1884 13.3464Z" fill="white"/>
    <path d="M82.7475 4.44891V5.33858H81.8577V4.44882L82.7475 4.44891Z" fill="white"/>
    <path d="M82.7475 5.33858V6.22844L81.8577 6.22835V5.33858H82.7475Z" fill="white"/>
    <path d="M85.4168 8.00778V8.89754L84.527 8.89764V8.00797L85.4168 8.00778Z" fill="white"/>
    <path d="M85.4168 6.22844V7.11811H84.527V6.22844H85.4168Z" fill="white"/>
    <path d="M88.0861 6.22835V7.1182L87.1963 7.11811V6.22844L88.0861 6.22835Z" fill="white"/>
    <path d="M79.1884 11.567V12.4567H78.2987V11.567H79.1884Z" fill="white"/>
    <path d="M81.8577 11.567V12.4567H80.9679V11.567H81.8577Z" fill="white"/>
    <path d="M84.527 11.567V12.4568L83.6372 12.4567V11.567H84.527Z" fill="white"/>
    <path d="M85.4168 13.3465V14.2363L84.527 14.2362V13.3465H85.4168Z" fill="white"/>
    <path d="M78.2987 13.3465V14.2363L77.4089 14.2362V13.3465L78.2987 13.3465Z" fill="white"/>
    <path d="M81.8577 8.00797V8.89764L80.9679 8.89773V8.00787L81.8577 8.00797Z" fill="white"/>
    <path d="M80.9679 8.00787V8.89773L80.0782 8.89764V8.00797L80.9679 8.00787Z" fill="white"/>
    <path d="M83.6372 9.7874V10.6773L82.7475 10.6772V9.78749L83.6372 9.7874Z" fill="white"/>
    <path d="M73.8498 6.22844V7.11811H72.9601L72.9601 6.22844H73.8498Z" fill="white"/>
    <path d="M76.5191 8.00797V8.89764H75.6294V8.00797H76.5191Z" fill="white"/>
    <path d="M79.1884 9.78749V10.6772H78.2987V9.78749H79.1884Z" fill="white"/>
    <path d="M104.102 9.25908e-05V0.889764L104.991 0.889669V0L104.102 9.25908e-05Z" fill="white"/>
    <path d="M104.102 0.889764V1.77953L104.991 1.77943V0.889669L104.102 0.889764Z" fill="white"/>
    <path d="M104.102 1.77953V2.66929L104.991 2.6692V1.77943L104.102 1.77953Z" fill="white"/>
    <path d="M103.212 1.77962V2.66929H104.102V1.77953L103.212 1.77962Z" fill="white"/>
    <path d="M104.991 1.77943V2.6692H105.881V1.77953L104.991 1.77943Z" fill="white"/>
    <path d="M104.991 2.6692V3.55905L105.881 3.55896V2.6692H104.991Z" fill="white"/>
    <path d="M103.212 2.66929V3.55905L104.102 3.55896V2.66929H103.212Z" fill="white"/>
    <path d="M104.102 2.66929V3.55896L104.991 3.55905V2.6692L104.102 2.66929Z" fill="white"/>
    <path d="M103.212 3.55905V4.44882L104.102 4.44872V3.55896L103.212 3.55905Z" fill="white"/>
    <path d="M104.102 3.55896V4.44872H104.991V3.55905L104.102 3.55896Z" fill="white"/>
    <path d="M105.881 3.55896V4.44882L104.991 4.44872V3.55905L105.881 3.55896Z" fill="white"/>
    <path d="M102.322 4.44891V5.33858L103.212 5.33849V4.44882L102.322 4.44891Z" fill="white"/>
    <path d="M102.322 5.33858V6.22835L103.212 6.22825V5.33849L102.322 5.33858Z" fill="white"/>
    <path d="M98.763 6.22844V7.11811L99.6528 7.11801V6.22835L98.763 6.22844Z" fill="white"/>
    <path d="M97.8733 6.22844V7.11811H98.763V6.22844H97.8733Z" fill="white"/>
    <path d="M100.543 8.00797V8.89764L101.432 8.89754V8.00787L100.543 8.00797Z" fill="white"/>
    <path d="M103.212 9.78749V10.6772L104.102 10.6771V9.7874L103.212 9.78749Z" fill="white"/>
    <path d="M103.212 4.44882V5.33849H104.102V4.44872L103.212 4.44882Z" fill="white"/>
    <path d="M103.212 5.33849V6.22825H104.102V5.33849H103.212Z" fill="white"/>
    <path d="M103.212 6.22825V7.11811L104.102 7.11801V6.22825H103.212Z" fill="white"/>
    <path d="M105.881 8.00797V8.89764L106.771 8.89754V8.00787L105.881 8.00797Z" fill="white"/>
    <path d="M99.6528 6.22835V7.11801H100.543V6.22835H99.6528Z" fill="white"/>
    <path d="M98.763 6.22844V7.11811L99.6528 7.11801V6.22835L98.763 6.22844Z" fill="white"/>
    <path d="M101.432 8.00787V8.89754H102.322V8.00787H101.432Z" fill="white"/>
    <path d="M104.102 9.7874V10.6771H104.991V9.7874H104.102Z" fill="white"/>
    <path d="M104.102 4.44872V5.33849H104.991V4.44872H104.102Z" fill="white"/>
    <path d="M104.102 5.33849V6.22825H104.991V5.33849H104.102Z" fill="white"/>
    <path d="M104.102 6.22825V7.11801H104.991V6.22825H104.102Z" fill="white"/>
    <path d="M106.771 8.00787V8.89754H107.661V8.00787H106.771Z" fill="white"/>
    <path d="M106.771 6.22844V7.11811L107.661 7.11801V6.22835L106.771 6.22844Z" fill="white"/>
    <path d="M109.44 6.22844V7.11811L110.33 7.11801V6.22835L109.44 6.22844Z" fill="white"/>
    <path d="M100.543 11.567V12.4567L101.432 12.4566V11.5669L100.543 11.567Z" fill="white"/>
    <path d="M103.212 11.567V12.4567L104.102 12.4566V11.5669L103.212 11.567Z" fill="white"/>
    <path d="M105.881 11.567V12.4567L106.771 12.4566V11.5669L105.881 11.567Z" fill="white"/>
    <path d="M106.771 13.3465V14.2362L107.661 14.2361V13.3465L106.771 13.3465Z" fill="white"/>
    <path d="M99.6528 13.3465V14.2362L100.543 14.2361V13.3465L99.6528 13.3465Z" fill="white"/>
    <path d="M100.543 6.22835V7.11801H101.432V6.22835H100.543Z" fill="white"/>
    <path d="M99.6528 6.22835V7.11801H100.543V6.22835H99.6528Z" fill="white"/>
    <path d="M102.322 8.00787V8.89754H103.212V8.00787H102.322Z" fill="white"/>
    <path d="M104.991 9.7874V10.6771H105.881V9.7874H104.991Z" fill="white"/>
    <path d="M105.881 4.44882V5.33858L104.991 5.33849V4.44872L105.881 4.44882Z" fill="white"/>
    <path d="M105.881 5.33858V6.22835L104.991 6.22825V5.33849L105.881 5.33858Z" fill="white"/>
    <path d="M105.881 6.22835V7.11811L104.991 7.11801V6.22825L105.881 6.22835Z" fill="white"/>
    <path d="M108.55 8.00797V8.89764L107.661 8.89754V8.00787L108.55 8.00797Z" fill="white"/>
    <path d="M108.55 6.22844V7.11811L107.661 7.11801V6.22835L108.55 6.22844Z" fill="white"/>
    <path d="M111.22 6.22844V7.11811L110.33 7.11801V6.22835L111.22 6.22844Z" fill="white"/>
    <path d="M102.322 11.567V12.4567L101.432 12.4566V11.5669L102.322 11.567Z" fill="white"/>
    <path d="M104.991 11.567V12.4567L104.102 12.4566V11.5669L104.991 11.567Z" fill="white"/>
    <path d="M107.661 11.567V12.4567L106.771 12.4566V11.5669L107.661 11.567Z" fill="white"/>
    <path d="M108.55 13.3465V14.2362L107.661 14.2361V13.3465L108.55 13.3465Z" fill="white"/>
    <path d="M101.432 13.3465V14.2362L100.543 14.2361V13.3465L101.432 13.3465Z" fill="white"/>
    <path d="M102.322 6.22835V7.11811L101.432 7.11801V6.22835H102.322Z" fill="white"/>
    <path d="M103.212 6.22825V7.11811H102.322V6.22835L103.212 6.22825Z" fill="white"/>
    <path d="M106.771 6.22844V7.11811H105.881V6.22835L106.771 6.22844Z" fill="white"/>
    <path d="M101.432 6.22835V7.11801H100.543V6.22835H101.432Z" fill="white"/>
    <path d="M104.102 8.00797V8.89764L103.212 8.89754V8.00787L104.102 8.00797Z" fill="white"/>
    <path d="M106.771 9.78749V10.6772L105.881 10.6771V9.7874L106.771 9.78749Z" fill="white"/>
    <path d="M96.9835 6.22844L96.9835 7.11811L96.0938 7.11801L96.0938 6.22835L96.9835 6.22844Z" fill="white"/>
    <path d="M99.6528 8.00797V8.89764L98.763 8.89754V8.00787L99.6528 8.00797Z" fill="white"/>
    <path d="M102.322 9.78749V10.6772L101.432 10.6771V9.7874L102.322 9.78749Z" fill="white"/>
    <path d="M98.763 8.00787V8.89754H97.8733V8.00787H98.763Z" fill="white"/>
    <path d="M101.432 9.7874V10.6771H100.543V9.7874H101.432Z" fill="white"/>
    <path d="M97.8733 7.11811V8.00787L96.9835 8.00778V7.11811H97.8733Z" fill="white"/>
    <path d="M100.543 8.89764V9.7874L99.6528 9.78731V8.89764H100.543Z" fill="white"/>
    <path d="M103.212 10.6772V11.567H102.322V10.6772H103.212Z" fill="white"/>
    <path d="M102.322 10.6772V11.567L101.432 11.5669V10.6771L102.322 10.6772Z" fill="white"/>
    <path d="M98.763 7.11811V8.00787H97.8733V7.11811H98.763Z" fill="white"/>
    <path d="M101.432 8.89754V9.7874H100.543V8.89764L101.432 8.89754Z" fill="white"/>
    <path d="M104.102 10.6771V11.5669L103.212 11.567V10.6772L104.102 10.6771Z" fill="white"/>
    <path d="M100.543 7.11801V8.00797H99.6528V7.11801H100.543Z" fill="white"/>
    <path d="M103.212 8.89754V9.78749H102.322V8.89754H103.212Z" fill="white"/>
    <path d="M105.881 10.6771V11.567H104.991V10.6771H105.881Z" fill="white"/>
    <path d="M99.6528 7.11801V8.00797L98.763 8.00787V7.11811L99.6528 7.11801Z" fill="white"/>
    <path d="M102.322 8.89754V9.78749L101.432 9.7874V8.89754H102.322Z" fill="white"/>
    <path d="M104.991 10.6771V11.567L104.102 11.5669V10.6771H104.991Z" fill="white"/>
    <path d="M101.432 7.11801V8.00787L100.543 8.00797V7.11801H101.432Z" fill="white"/>
    <path d="M104.102 8.89764V9.7874L103.212 9.78749V8.89754L104.102 8.89764Z" fill="white"/>
    <path d="M106.771 10.6772V11.5669L105.881 11.567V10.6771L106.771 10.6772Z" fill="white"/>
    <path d="M102.322 7.11811V8.00787H101.432V7.11801L102.322 7.11811Z" fill="white"/>
    <path d="M104.991 8.89773V9.7874H104.102V8.89764L104.991 8.89773Z" fill="white"/>
    <path d="M107.661 10.6773V11.567L106.771 11.5669V10.6772L107.661 10.6773Z" fill="white"/>
    <path d="M103.212 7.11811V8.00787H102.322V7.11811H103.212Z" fill="white"/>
    <path d="M105.881 8.89764V9.7874H104.991V8.89773L105.881 8.89764Z" fill="white"/>
    <path d="M108.55 9.78749V10.6772L107.661 10.6773V9.7874L108.55 9.78749Z" fill="white"/>
    <path d="M104.102 7.11801V8.00797L103.212 8.00787V7.11811L104.102 7.11801Z" fill="white"/>
    <path d="M106.771 8.89754V9.78749L105.881 9.7874V8.89764L106.771 8.89754Z" fill="white"/>
    <path d="M104.991 7.11801V8.00787L104.102 8.00797V7.11801H104.991Z" fill="white"/>
    <path d="M107.661 8.89754V9.7874L106.771 9.78749V8.89754H107.661Z" fill="white"/>
    <path d="M107.661 7.11801V8.00787H106.771V7.11811L107.661 7.11801Z" fill="white"/>
    <path d="M110.33 7.11801V8.00787L109.44 8.00778V7.11811L110.33 7.11801Z" fill="white"/>
    <path d="M101.432 12.4566V13.3465L100.543 13.3465V12.4567L101.432 12.4566Z" fill="white"/>
    <path d="M104.102 12.4566V13.3465L103.212 13.3464V12.4567L104.102 12.4566Z" fill="white"/>
    <path d="M106.771 12.4566V13.3465L105.881 13.3464V12.4567L106.771 12.4566Z" fill="white"/>
    <path d="M107.661 14.2361V15.126L106.771 15.1259V14.2362L107.661 14.2361Z" fill="white"/>
    <path d="M106.771 13.3465V14.2362L105.881 14.2361V13.3464L106.771 13.3465Z" fill="white"/>
    <path d="M100.543 14.2361V15.126L99.6528 15.1259V14.2362L100.543 14.2361Z" fill="white"/>
    <path d="M100.543 15.126V16.0157L99.6528 16.0157V15.1259L100.543 15.126Z" fill="white"/>
    <path d="M109.44 15.1261V16.0157L108.55 16.0157V15.126L109.44 15.1261Z" fill="white"/>
    <path d="M109.44 16.0157V16.9055L108.55 16.9054V16.0157L109.44 16.0157Z" fill="white"/>
    <path d="M99.6528 15.1259V16.0157H98.763V15.126L99.6528 15.1259Z" fill="white"/>
    <path d="M108.55 15.126V16.0157H107.661V15.126H108.55Z" fill="white"/>
    <path d="M99.6528 16.0157V16.9055L98.763 16.9054V16.0157H99.6528Z" fill="white"/>
    <path d="M98.763 16.9054V17.7953L97.8733 17.7952V16.9055L98.763 16.9054Z" fill="white"/>
    <path d="M100.543 16.0157V16.9055H99.6528V16.0157L100.543 16.0157Z" fill="white"/>
    <path d="M101.432 15.1261V16.0157H100.543V15.126L101.432 15.1261Z" fill="white"/>
    <path d="M110.33 15.1261V16.0157H109.44V15.1261H110.33Z" fill="white"/>
    <path d="M110.33 16.0157V16.9055H109.44V16.0157H110.33Z" fill="white"/>
    <path d="M111.22 16.9056V17.7953L110.33 17.7952V16.9055L111.22 16.9056Z" fill="white"/>
    <path d="M105.881 7.11811V8.00797L104.991 8.00787V7.11801L105.881 7.11811Z" fill="white"/>
    <path d="M108.55 8.89764V9.78749L107.661 9.7874V8.89754L108.55 8.89764Z" fill="white"/>
    <path d="M108.55 7.11811V8.00797L107.661 8.00787V7.11801L108.55 7.11811Z" fill="white"/>
    <path d="M111.22 7.11811V8.00787H110.33V7.11801L111.22 7.11811Z" fill="white"/>
    <path d="M111.22 8.00787V8.89764L110.33 8.89754V8.00787H111.22Z" fill="white"/>
    <path d="M110.33 8.00787V8.89754H109.44V8.00778L110.33 8.00787Z" fill="white"/>
    <path d="M112.109 7.1182V8.00787H111.22V7.11811L112.109 7.1182Z" fill="white"/>
    <path d="M102.322 12.4567V13.3465L101.432 13.3465V12.4566L102.322 12.4567Z" fill="white"/>
    <path d="M104.991 12.4567V13.3465H104.102V12.4566L104.991 12.4567Z" fill="white"/>
    <path d="M107.661 12.4567V13.3465L106.771 13.3465V12.4566L107.661 12.4567Z" fill="white"/>
    <path d="M108.55 14.2362V15.126H107.661V14.2361L108.55 14.2362Z" fill="white"/>
    <path d="M101.432 14.2362V15.1261L100.543 15.126V14.2361L101.432 14.2362Z" fill="white"/>
    <path d="M102.322 14.2363V15.126L101.432 15.1261V14.2362L102.322 14.2363Z" fill="white"/>
    <path d="M106.771 7.11811V8.00787L105.881 8.00797V7.11811H106.771Z" fill="white"/>
    <path d="M109.44 8.89754V9.7874L108.55 9.78749V8.89764L109.44 8.89754Z" fill="white"/>
    <path d="M109.44 7.11811V8.00778L108.55 8.00797V7.11811H109.44Z" fill="white"/>
    <path d="M112.999 6.22844V7.11811L112.109 7.1182V6.22835L112.999 6.22844Z" fill="white"/>
    <path d="M103.212 12.4567V13.3464L102.322 13.3465V12.4567H103.212Z" fill="white"/>
    <path d="M105.881 12.4567V13.3464L104.991 13.3465V12.4567H105.881Z" fill="white"/>
    <path d="M108.55 12.4568V13.3465L107.661 13.3465V12.4567L108.55 12.4568Z" fill="white"/>
    <path d="M109.44 14.2363V15.1261L108.55 15.126V14.2362L109.44 14.2363Z" fill="white"/>
    <path d="M103.212 13.3464V14.2362L102.322 14.2363V13.3465L103.212 13.3464Z" fill="white"/>
    <path d="M106.771 4.44891V5.33858H105.881V4.44882L106.771 4.44891Z" fill="white"/>
    <path d="M106.771 5.33858V6.22844L105.881 6.22835V5.33858H106.771Z" fill="white"/>
    <path d="M109.44 8.00778V8.89754L108.55 8.89764V8.00797L109.44 8.00778Z" fill="white"/>
    <path d="M109.44 6.22844V7.11811H108.55V6.22844H109.44Z" fill="white"/>
    <path d="M112.109 6.22835V7.1182L111.22 7.11811V6.22844L112.109 6.22835Z" fill="white"/>
    <path d="M103.212 11.567V12.4567H102.322V11.567H103.212Z" fill="white"/>
    <path d="M105.881 11.567V12.4567H104.991V11.567H105.881Z" fill="white"/>
    <path d="M108.55 11.567V12.4568L107.661 12.4567V11.567H108.55Z" fill="white"/>
    <path d="M109.44 13.3465V14.2363L108.55 14.2362V13.3465H109.44Z" fill="white"/>
    <path d="M102.322 13.3465V14.2363L101.432 14.2362V13.3465L102.322 13.3465Z" fill="white"/>
    <path d="M105.881 8.00797V8.89764L104.991 8.89773V8.00787L105.881 8.00797Z" fill="white"/>
    <path d="M104.991 8.00787V8.89773L104.102 8.89764V8.00797L104.991 8.00787Z" fill="white"/>
    <path d="M107.661 9.7874V10.6773L106.771 10.6772V9.78749L107.661 9.7874Z" fill="white"/>
    <path d="M97.8733 6.22844V7.11811H96.9835L96.9835 6.22844H97.8733Z" fill="white"/>
    <path d="M100.543 8.00797V8.89764H99.6528V8.00797H100.543Z" fill="white"/>
    <path d="M103.212 9.78749V10.6772H102.322V9.78749H103.212Z" fill="white"/>
  </svg>
)
