
export const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="209" height="30" viewBox="0 0 209 30" fill="none">
    <path d="M67.4269 19.1676H59.1099V16.2616H65.2149V12.6292H59.1099V9.72324H67.4269V6.09081H55.4823V22.8H67.4269V19.1676Z" fill="white"/>
    <path d="M44.8649 6.09081H39.4677V16.08H42.9183V9.72324H44.9534C47.1653 9.72324 49.7312 10.9946 49.7312 14.4454C49.7312 17.987 47.1653 19.1676 44.9534 19.1676H39.5562V22.8H44.9534C49.7312 22.8 53.2703 19.1676 53.2703 14.4454C53.1818 9.63243 49.6427 6.09081 44.8649 6.09081Z" fill="white"/>
    <path d="M108.658 6.09081L102.73 22.8H106.357L107.861 18.4411H113.613L115.117 22.8H118.744L112.816 6.09081H108.658ZM109.189 14.8086L110.781 10.4497L112.374 14.8086H109.189Z" fill="white"/>
    <path d="M92.2893 8.99676H89.3695V22.8H92.9971V16.8973H96.3593V13.3557H92.9971V9.72324H101.491V6.09081H92.2893V8.99676Z" fill="white"/>
    <path d="M186.961 11.9027C186.961 8.45189 184.661 6.09081 181.387 6.09081H178.821V9.72324H181.387C183.157 9.72324 183.422 11.1762 183.422 11.9935C183.422 13.3557 182.803 13.9914 181.564 13.9914H178.821V9.72324H175.282V22.7092H178.821V17.3513H180.768V20.1665H183.51V22.8H186.961V19.44H184.218V17.0789C185.899 16.1708 186.961 14.3546 186.961 11.9027Z" fill="white"/>
    <path d="M139.448 6.09081V9.72324H143.961V22.8H147.588V9.72324H152.101V6.09081H139.448Z" fill="white"/>
    <path d="M84.7686 19.1676H72.0277V22.8H84.7686V19.1676Z" fill="white"/>
    <path d="M199.614 6.09081L196.517 11.7211L193.332 6.09081H189.262L194.747 15.9892V22.8H198.198V15.9892L203.772 6.09081H199.614Z" fill="white"/>
    <path d="M36.3709 11.3578H33.982V8.90595H31.5046V6.45405C30.6199 6.18162 29.7351 6 28.7618 6C24.2494 6 20.6218 9.72324 20.6218 14.3546C20.6218 18.9859 24.2494 22.7092 28.7618 22.7092C33.2742 22.7092 36.9018 18.9859 36.9018 14.3546C36.9018 13.2649 36.7249 12.2659 36.3709 11.3578ZM28.7618 19.0768C26.1959 19.0768 24.1609 16.9881 24.1609 14.3546C24.1609 11.7211 26.1959 9.63243 28.7618 9.63243C29.4696 9.63243 30.089 9.81405 30.6199 10.0865V12.3568H32.9203C33.1857 12.9924 33.3627 13.6281 33.3627 14.3546C33.3627 16.9881 31.3277 19.0768 28.7618 19.0768Z" fill="white"/>
    <path d="M170.504 11.3578H168.115V8.90595H165.638V6.45405C164.753 6.18162 163.868 6 162.895 6C158.383 6 154.755 9.72324 154.755 14.3546C154.755 18.9859 158.383 22.7092 162.895 22.7092C167.407 22.7092 171.035 18.9859 171.035 14.3546C171.035 13.2649 170.77 12.2659 170.504 11.3578ZM162.895 19.0768C160.329 19.0768 158.294 16.9881 158.294 14.3546C158.294 11.7211 160.329 9.63243 162.895 9.63243C163.603 9.63243 164.222 9.81405 164.753 10.0865V12.3568H167.053C167.319 12.9924 167.496 13.6281 167.496 14.3546C167.496 16.9881 165.461 19.0768 162.895 19.0768Z" fill="white"/>
    <path d="M17.0827 17.0789C16.2864 18.2595 14.8707 19.0768 13.3666 19.0768C12.6587 19.0768 12.0394 18.8951 11.5085 18.6227V16.3524H9.20809C8.94265 15.7168 8.7657 15.0811 8.7657 14.3546C8.7657 11.7211 10.8007 9.63243 13.3666 9.63243C14.6053 9.63243 15.7555 10.1773 16.6403 10.9946L19.737 9.17838C18.2329 7.27135 15.9324 6 13.3666 6C8.85418 6 5.22656 9.72324 5.22656 14.3546C5.22656 15.3535 5.40352 16.3524 5.75743 17.3513H8.14635V19.8941H10.6237V22.2551C11.5085 22.5276 12.3933 22.7092 13.3666 22.7092C16.1979 22.7092 18.6753 21.2562 20.1794 18.9859L17.0827 17.0789Z" fill="white"/>
    <path d="M133.432 17.0789C132.635 18.2595 131.22 19.0768 129.716 19.0768C129.008 19.0768 128.388 18.8951 127.858 18.6227V16.3524H125.557C125.292 15.7168 125.115 15.0811 125.115 14.3546C125.115 11.7211 127.15 9.63243 129.716 9.63243C130.954 9.63243 132.105 10.1773 132.989 10.9946L136.086 9.17838C134.582 7.27135 132.281 6 129.716 6C125.203 6 121.576 9.72324 121.576 14.3546C121.576 15.3535 121.753 16.3524 122.106 17.3513H124.495V19.8941H126.973V22.2551C127.858 22.5276 128.742 22.7092 129.716 22.7092C132.547 22.7092 135.024 21.2562 136.528 18.9859L133.432 17.0789Z" fill="white"/>
  </svg>
);
