function defaultRandomRingPoint(
  r1: number,
  r2: number,
) {
  return r1 + Math.cbrt(Math.random()) * (r2 - r1)
}


export function getRandomBallPoint(
  r1 = 0,
  r2 = 1,
  getRadius = defaultRandomRingPoint,
) {
  const u = Math.random()
  const v = Math.random()
  const theta = u * 2.0 * Math.PI
  const phi = Math.acos(2.0 * v - 1.0)
  const r = getRadius(r1, r2)
  const sinTheta = Math.sin(theta)
  const cosTheta = Math.cos(theta)
  const sinPhi = Math.sin(phi)
  const cosPhi = Math.cos(phi)
  const x = r * sinPhi * cosTheta
  const y = r * sinPhi * sinTheta
  const z = r * cosPhi
  return { x, y, z }
}
