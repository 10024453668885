import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import MoiraOneLogo from 'assets/img/miuraOneLogo.png';
import { BLine } from '../BLine';

function FooterComponent() {
  return (
    <div>
      <BLine />
      <footer>
        <span className="designed">
        DESIGNED BY
        <a href="https://miura.one/" target="_blank">
          <img src={MoiraOneLogo} alt="Miura One" />
        </a>
      </span>

        <Link to="policy">PRIVACY POLICY</Link>

        <span className="codeFactory">© 2023 CODE FACTORY OU</span>
      </footer>
    </div>
  );
}

export const Footer = memo(FooterComponent);
