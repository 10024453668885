import React, { memo } from 'react';


const ServicesPageComponent = () => {
  return (
    <div>
      SERVICES
    </div>
  );
};

export const ServicesPage = memo(ServicesPageComponent);
