import React, { memo, useEffect, useRef, useState } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';
import cx from 'classnames';

type Props = {
  text: string;
}

function AnimatedTextComponent(props: Props) {
  const [inView, setInView] = useState(false);
  const line = useRef();
  const data = useIntersectionObserver(line, {
    threshold: 0,
    root: null
  });

  useEffect(() => {
    if (data?.isIntersecting) {
      setInView(true);
    }
  }, [data?.isIntersecting]);

  return (
    <>
      {props.text.split(' ').map(letter => (
        <span ref={line} className={cx('animated-text', { inView })}>{letter + ' '}</span>
      ))}
    </>
  );
}

export const AnimatedText = memo(AnimatedTextComponent);
