import React, { memo, useCallback, useEffect, useState } from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { Outlet } from 'react-router-dom';
import cx from 'classnames';

export enum Breakpoints {
  over = 1920,
  max = 1440,
  mobile = 768,
  small = 375
}

function LayoutComponent() {
  const [loading, setLoading] = useState(true);
  const [zoom, setZoom] = useState(1);

  const onResize = useCallback(() => {
    const width = document.body.clientWidth;

    if (width > Breakpoints.over) {
      setZoom(Breakpoints.over / Breakpoints.max);
    } else if (width > Breakpoints.mobile) {
      setZoom(width / Breakpoints.max);
    } else if (width < Breakpoints.mobile) {
      setZoom(width / Breakpoints.small);
    } else {
      setZoom(1);
    }
  }, []);

  useEffect(() => {
    onResize();

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 750);
  }, [])

  return (
    <div className={cx('app container', { loading })} style={{ zoom }}>
      <div className='preloader'></div>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export const Layout = memo(LayoutComponent);
