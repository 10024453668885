
interface SocialItem {
  label: string;
  link: string;
  bg: string;
}
export const SOCIALS: SocialItem[] = [
  {
    label: 'Linkedin',
    link: '',
    bg: '#494949'
  },
  {
    label: 'Facebook',
    link: '',
    bg: '#494949'
  },
  {
    label: 'Medium',
    link: '',
    bg: '#494949'
  },
  {
    label: 'Github',
    link: '',
    bg: '#494949'
  },
]
