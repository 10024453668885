import React, { memo, useEffect, useMemo, useState } from 'react';
import { ExpandableTable, SearchInput } from '../../components';
import { WORKS_DATA } from '../../data';

const WorksPageComponent = () => {
  const [search, setSearch] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const onResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    onResize();

    document.addEventListener('resize', onResize);

    return () => {
      document.removeEventListener('resize', onResize);
    };
  }, []);

  const handleSearchValue = v => {
    setSearch(v);
  };

  const filterItems = useMemo(() => {
    let data = [...WORKS_DATA];

    if (search) {
      data = data.filter(v => JSON.stringify(v).toLowerCase().includes(search.toLowerCase()));
    }

    return data;
  }, [search]);

  return (
    <div className="works">
      <div className="works-searchContainer">
        <h2 className="title">ALL WORKS</h2>

        <div className="works-search">
          <SearchInput
            placeholder={isMobile ? 'Search by project' : 'Search by project, technology, keywords...'}
            onChange={handleSearchValue}
          />
        </div>
      </div>

      <ExpandableTable items={filterItems} />
    </div>
  );
};

export const WorksPage = memo(WorksPageComponent);
