export const pr = Math.min(window.devicePixelRatio, 2)

export const pi = Math.PI

export const tau = pi * 2



export const mainParticlesCount = 20000

export const areaParticlesCount = 5000

// ring radius
export const R = {
  min: 3,
  max: 13,
  get d() { return R.max - R.min },
}