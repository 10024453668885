import React, { memo } from 'react';
import { AccordionContainer, AccordionContainerItem } from '../../components';

const TravelTechContent = () => (
  <>
    <h3>1. Online Travel Agencies (OTAs):</h3>
    <p>
      OTAs are platforms that allow travelers to book flights, accommodations, and other travel services online. They
      provide a convenient way to compare prices, read reviews, and make reservations. Examples include Expedia,
      Booking.com, and Kayak.
    </p>

    <h3>2. Travel Planning and Research:</h3>
    <p>
      Various websites and mobile apps assist travelers in planning their trips. These platforms offer information on
      destinations, attractions, local transportation, and travel itineraries. They may also suggest personalized
      recommendations based on user preferences.
    </p>

    <h3>3. Mobile Applications:</h3>
    <p>
      Travel apps are designed to enhance the travel experience by providing real-time information, services, and
      features on smartphones and tablets. These apps can offer functionalities such as flight and hotel bookings,
      navigation, language translation, currency conversion, and local recommendations.
    </p>

    <h3>4. Accommodation Sharing Platforms:</h3>
    <p>
      Platforms like Airbnb and HomeAway enable individuals to rent out their homes, apartments, or spare rooms to
      travelers. These services provide an alternative to traditional hotels and offer unique, localized experiences.
    </p>

    <h3>5. Smart Luggage and Travel Accessories:</h3>
    <p>
      Innovative luggage and travel accessories incorporate technology, such as built-in trackers, USB charging ports,
      digital locks, and weight sensors. These features enhance convenience, security, and efficiency for travelers.
    </p>
  </>
);

const DATA: AccordionContainerItem[] = [
  { title: 'TravelTech', content: <TravelTechContent /> },
  { title: 'Payment systems', content: <TravelTechContent /> },
  { title: 'E-commerce', content: <TravelTechContent /> },
  { title: 'Analytical systems', content: <TravelTechContent /> },
  { title: 'PropTech', content: <TravelTechContent /> }
];

function ExperienceComponent() {
  return <AccordionContainer data={DATA} blockTitle="OUR expertise" blockTitleLink="#" />;
}

export const Expertise = memo(ExperienceComponent);
