import gsap, { Power3, Power4 } from 'gsap'
import { Clock, Group, PerspectiveCamera, Scene, WebGLRenderer } from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import { onPointerMove } from './utils/pointerMove'
import { pr } from './utils/const'
import { createParticles } from './mainParticles'
import { areaParticles } from './areaParticles'



//////// SETUP

const clock = new Clock()

let width = 0
let height = 0
let canvas: HTMLCanvasElement
let renderer: WebGLRenderer
// let controls: OrbitControls

const scene = new Scene()

const cameraPivot = new Group()
const camera = new PerspectiveCamera(75, 1, 0.1, 100)
cameraPivot.add(camera)
scene.add(cameraPivot)

cameraPivot.position.set(0, 0, 10)


export function initParticles(canvasRef: HTMLCanvasElement) {
  canvas = canvasRef
  width  = canvas.parentElement.clientWidth
  height = canvas.parentElement.clientHeight

  camera.aspect = width / height
  camera.updateProjectionMatrix()

  // controls = new OrbitControls(camera, canvas)
  // controls.update()

  renderer = new WebGLRenderer({ canvas })
  renderer.setSize(width, height)
  renderer.setPixelRatio(pr)
  renderer.setClearColor(0x000000, 0)
  renderer.setAnimationLoop(animationLoop)

  canvasResizer.observe(canvas.parentElement)
}

export function disposeParticles() {
  renderer.dispose()
}



//////// PARTICLES

const particlesPivot = new Group()

const particles = createParticles()
particles.position.set(6, -1.5, 0)

particlesPivot.add(particles)

areaParticles.position.copy(particles.position)
areaParticles.rotation.copy(particles.rotation)
particlesPivot.add(areaParticles)

scene.add(particlesPivot)



//// POINTER

onPointerMove(function(x, y) {
  camera.position.x = -x * 4
  camera.position.y = -y * 4
  camera.lookAt(scene.position)
  particlesPivot.rotation.y =  x * Math.PI * 0.125
  particlesPivot.rotation.x = -y * Math.PI * 0.125
})



//////// ANIMATION ON START

// point position scale
gsap.to(particles.material.uniforms.uPosScale, {
  ease: Power3.easeOut,
  duration: 4,
  value: 1,
  onUpdate() {
    const { value } = particles.material.uniforms.uPosScale
    areaParticles.material.uniforms.uPosScale.value = value
  },
})
// point size scale
gsap.to(particles.material.uniforms.uPointSizeScale, {
  ease: Power3.easeOut,
  duration: 2,
  value: 5,
  onUpdate() {
    const { value } = particles.material.uniforms.uPointSizeScale
    areaParticles.material.uniforms.uPointSizeScale.value = value
  },
})
// points rotation speed
gsap.to(particles.material.uniforms.uInitRotationSpeed, {
  ease: Power4.easeOut,
  duration: 5,
  value: 0,
  onUpdate() {
    const { value } = particles.material.uniforms.uInitRotationSpeed
    areaParticles.material.uniforms.uInitRotationSpeed.value = value
  },
})
// mesh rotation
gsap.to(particles.rotation, {
  ease: Power4.easeOut,
  duration: 9,
  x: 0,
  y: -Math.PI * 0.2,
  onUpdate() {
    areaParticles.rotation.copy(particles.rotation)
  },
})



//////// HELPERS

function animationLoop() {
  particles.material.uniforms.uTime.value += clock.getDelta() * 0.1
  renderer.render(scene, camera)
}

window.addEventListener('visibilitychange', function() {
  clock[document.visibilityState === 'visible' ? 'start' : 'stop']()
})

const canvasResizer = new ResizeObserver(function() {
  width = canvas.parentElement.clientWidth
  height = canvas.parentElement.clientHeight

  renderer.setSize(width, height)

  camera.aspect = width / height
  camera.updateProjectionMatrix()
})
