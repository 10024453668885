import React, { memo } from 'react';
import { ThreejsContainer } from './ThreejsContainer';
import { AnimatedText, BLine } from '../../components';

function Top() {
  return (
    <div className="topContainer">
      <div className="topContainer-main">
        <ThreejsContainer />
        <div className="topContainer-content">
          <div className="topContainer-content-space">
            <BLine vertical />
          </div>
          <div className="topContainer-content-title">
            <h1>
              <AnimatedText text="Dedicated teams for your IT project" />
            </h1>

            <div className='tags bordered'>
              <span>Science</span>
              <span>Algorithms</span>
              <span>Algorithms</span>
            </div>
          </div>
        </div>

        <div className="topContainer-divider"/>
      </div>
      <BLine />
    </div>
  );
}

export const TopContainer = memo(Top);
