import React from 'react';
import { Layout } from './components';
import { AboutPage, ContactPage, MainPage, PricesPage, ServicesPage, WorksPage } from './pages';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { DetailWork } from './pages/Works/DetailWork';

const router = createBrowserRouter([
  {
    path: '',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <MainPage />
      },
      {
        path: '/services',
        element: <ServicesPage />
      },
      {
        path: '/works',
        element: <WorksPage />
      },
      {
        path: '/works/:workId',
        element: <DetailWork />
      },
      {
        path: '/about',
        element: <AboutPage />
      },
      {
        path: '/prices',
        element: <PricesPage />
      },
      {
        path: '/contact',
        element: <ContactPage />
      }
    ]
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
