import React, { useEffect, useMemo, useRef, useState } from 'react';

function DashedDividerComponent() {
  const ref = useRef<any>(null);

  const [lineWidth, setLineWidth] = useState(0);

  useMemo(() => ref.current?.clientWidth || 0, [ref.current]);

  useEffect(() => {
    setLineWidth(ref.current?.clientWidth);
  }, [ref.current]);

  return (
    <div ref={ref} className="dashed-divider">
      {Boolean(lineWidth) && (
        <svg width="100%" height="1" viewBox={`0 0 ${lineWidth} 1`} fill="none" xmlns="http://www.w3.org/2000/svg">
          <line
            x1="0"
            y1="0.5"
            x2={lineWidth}
            y2="0.499954"
            stroke="url(#paint0_radial_1853_787)"
            strokeDasharray="2 2"
          />
          <defs>
            <radialGradient
              id="paint0_radial_1853_787"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(116.089 1.00006) scale(235.403 38318.8)"
            >
              <stop stopColor="#797979" />
              <stop offset="1" stopColor="white" />
            </radialGradient>
          </defs>
        </svg>
      )}
    </div>
  );
}

export const DashedDivider = DashedDividerComponent;
