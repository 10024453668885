import { AdditiveBlending, BufferAttribute, BufferGeometry, Points, RawShaderMaterial } from 'three'

import { R, mainParticlesCount, pi, pr } from './utils/const'
import { getRandomBallPoint } from './utils/getRandomBallPoint'
import { float, glPow2, glPow5, glRotate } from './utils/glUtils'
import { fragmentShader } from './utils/fragment'



export class ParticlesMaterial extends RawShaderMaterial {

  uniforms = {
    uTime: { value: 0 },

    uPosScale: { value: 0 }, // 1
    uInitRotationSpeed: { value: 5 }, // 0

    uPixelRatio: { value: pr },
    uPointSizeScale: { value: 0 }, // 1
    uOpacity: { value: 0.4 },
  }

  vertexShader = vertexShader
  fragmentShader = fragmentShader
  transparent = true
  blending = AdditiveBlending
  depthTest = false

}



export function createParticles() {
  const geometry = new BufferGeometry()
  const dataArray = new Float32Array(mainParticlesCount * 3)
  const offsetArray = new Float32Array(mainParticlesCount * 3)

  for (let i = 0; i < mainParticlesCount; i++) {
    // angle
    dataArray[i * 4 + 0] = Math.random() * Math.PI * 2
    // radius
    dataArray[i * 4 + 1] = Math.random() * R.d
    // size
    dataArray[i * 4 + 2] = 0.4 + Math.random() * 0.6
    // seed
    dataArray[i * 4 + 3] = Math.random()

    const offset = getRandomBallPoint(0, 0.3)
    offsetArray[i * 3 + 0] = offset.x
    offsetArray[i * 3 + 1] = offset.y
    offsetArray[i * 3 + 2] = offset.z
  }

  geometry.setAttribute('position', new BufferAttribute(dataArray, 4))
  geometry.setAttribute('posOffset', new BufferAttribute(offsetArray, 3))

  const particles = new Points(geometry, new ParticlesMaterial())
  particles.rotation.set(-Math.PI / 4, -Math.PI / 8, 0)

  return particles
}



//// SHADER

const vertexShader = /*glsl*/`
uniform mat4 projectionMatrix;
uniform mat4 viewMatrix;
uniform mat4 modelMatrix;

attribute vec4 position;
attribute vec3 posOffset;

// 
uniform float uTime;
uniform float uPosScale;

uniform float uInitRotationSpeed;

uniform float uPixelRatio;
uniform float uPointSizeScale;

//
#define PI ${pi}

${glRotate}
${glPow2}
${glPow5}


void main()
{
  float a = position.x; // angle
  float r = position.y; // radius
  float s = position.z; // size
  float o = position.w; // seed


  float minRadius = ${float(R.min)};
  float maxRadius = ${float(R.d)} * (pow5(o) + pow5(s));
  float radius = minRadius + mod(r + 3.0 * uTime * (0.5 + o), maxRadius);

  float rn = radius * ${1 / (R.max)}; // normalized radius
  float rm = (1.0 - sqrt(rn)) * (0.3 / (pow2(rn))); // magic radius (bigger value to the center)


  // position
  vec3 p = vec3(cos(a), sin(a), 0.0) * radius;


  // rotate, faster near center
  p.xy = rotate(p.xy, uTime * 0.3 - rm - uInitRotationSpeed);


  // breath
  float breath = sin(uTime * 10.0);
  float breathLength = 0.1;
  p.xy *= 1.0 + breath * breathLength * rm;


  // fly in from z-infinity
  float fr = 0.5; // effect distance from min radius
  float rr = min(radius - minRadius, fr) / fr;
  p.z = - pow2(tan(0.5 * (PI * sqrt(rr) + PI)));
  s *= 1.0 - pow5((1.0 - rr));


  vec4 vp = viewMatrix * modelMatrix * vec4(uPosScale * (p + posOffset), 1.0);
  gl_Position = projectionMatrix * vp;
  gl_PointSize = uPointSizeScale * s * uPixelRatio * 10.0 / -vp.z;
}`
