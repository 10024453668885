import React, { memo } from 'react';
import { ExpandableTable, Forms, Title } from 'app/components';
import { TopContainer } from './TopContainer';
import { Expertise } from './Expertise';
import { Medium } from './Medium';
import { About } from './About';
import { Reviews } from './Reviews';
import { FindUs } from './FindUs';
import { WORKS_DATA } from '../../data';

const MainPageComponent = () => {
  return (
    <div>
      <TopContainer />
      <Expertise />
      <div>
        <Title label="Selected works" link="/works" />
        <ExpandableTable items={WORKS_DATA.slice(0, 8)} />
      </div>
      <Medium />
      <About />
      <Reviews />
      <FindUs />
      <Forms />
    </div>
  );
};

export const MainPage = memo(MainPageComponent);
