import React, { memo } from 'react';
import SphereImg from 'assets/img/sphere.png';
import { BLine, Title } from '../../components';

function MediumContainer() {
  return (
    <div>
      <Title label="MEDIUM" link="#" />
      <BLine />
      <div className="medium">
        <div>
          <img src={SphereImg} alt="Spere image" />
          <div>
            <h3>Computer science & algorithms</h3>
            <div className="tags bordered">
              <span>Science</span>
              <span>Algorithms</span>
            </div>
          </div>
        </div>
        <div>
          <div className="readMore">
            <span>READ MORE</span>
            <div className="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                <path d="M32.4853 34.1127L35.3137 31.2843L32.4853 28.4558L35.3137 25.6274L32.4853 22.799L35.3137 19.9706L32.4853 17.1421L35.3137 14.3137L32.4853 11.4853L29.6569 14.3137L26.8284 11.4853L24 14.3137L21.1716 11.4853L18.3431 14.3137L15.5147 11.4853L12.6863 14.3137L21.1716 22.799L11.2721 32.6985L14.1005 35.5269L24 25.6274L32.4853 34.1127Z" fill="black"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <BLine />
    </div>
  );
}

export const Medium = memo(MediumContainer);
