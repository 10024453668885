const VELOCITY  = 4e-4
const THRESHOLD = 3e-3

// from -0.5 to 0.5
const linePointer = { x: 0, y: 0 }
const easePointer = { x: 0, y: 0 }



export function onPointerMove(
  handler: (x: number, y: number) => void,
) {
  let easing = false
  let lastTime = 0
  let raf: number


  function moveEasePointer() {
    const currTime = performance.now()
    const dt = (currTime - lastTime) * VELOCITY
    const dx = linePointer.x - easePointer.x
    const dy = linePointer.y - easePointer.y
    easePointer.x += dx * dt
    easePointer.y += dy * dt
    lastTime = currTime

    handler(easePointer.x, easePointer.y)

    if (Math.abs(dx) + Math.abs(dy) > THRESHOLD)
      raf = requestAnimationFrame(moveEasePointer)
    else
      easing = false
  }


  window.addEventListener('pointermove', function(e) {
    linePointer.x =  e.clientX / window.innerWidth  - 0.5
    linePointer.y = -e.clientY / window.innerHeight + 0.5

    if (easing) return

    easing = true
    lastTime = performance.now()
    raf = requestAnimationFrame(moveEasePointer)
  })


  window.addEventListener('visibilitychange', function() {
    if (document.visibilityState === 'visible') return

    easing = false
    cancelAnimationFrame(raf)
  })
}
